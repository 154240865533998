import React from 'react';

const EventDescription = (description) => {
    description = JSON.parse(description.state);
    return (
        <div className="event-description">
            {description}
            <style jsx>
            {`
                .event-description {
                    color: var(--primary-main, #82bbb6);
                    font-family: YuPearl, sans-serif;
                    margin-top: 16px;
                    width: 100%;
                    margin-bottom: 24px;
                }
                .more-link {
                    color: rgba(130,187,182,1);
                }
            `}
            </style>
        </div>
    );
};

export default EventDescription;