import React, { useContext, useEffect, useState } from 'react'
import { useParams, useNavigate, useLocation, Link  } from 'react-router-dom';
import SlotItem from './slot_picker_item';
import { AccountContext } from '../../Account';
import { generateClient } from 'aws-amplify/api';
import { getEvent, getEventDateTime, getUser } from '../../graphql/queries';
import { updateUser, updateEventDateTime } from '../../graphql/mutations';

const SlotDetail = () => {
   
    const activityID = useLocation().state.id;
    let navigate = useNavigate();
    const monthNames = ["一月", "二月", "三月", "四月", "五月", "六月", "七月", "八月", "九月", "十月", "十一月", "十二月"];

    const [state, setState] = useState(false);
    const [id, setEventID] = useState();
    const [userID, setUserID] = useState("");
    const [userJoinList, setUserJoinList] = useState([]);
    const [user, setUser] = useState("");
    const [event, setEvent] = useState();
    const [slot, setSlot] = useState();
    const [participantList, setParticipantList] = useState([]);
    const { getSession, logout } = useContext(AccountContext);

    const client = generateClient();

    const today = new Date();
    var t_monthVal = today.getMonth() + 1;
    var t_dayVal = today.getDate();
    if(t_monthVal < 10){
        t_monthVal = "0" + t_monthVal;
    }
    if(t_dayVal < 10){
        t_dayVal = "0" + t_dayVal;
    }

    const date = today.getFullYear() + "-" + t_monthVal + "-" + t_dayVal;
    const time = today.getHours() + ":" + today.getMinutes();

    async function fetchSlot() {
        try {
            const slotData = await client.graphql({
                    query: getEventDateTime,
                    variables: { id: activityID }
            });
            const slot = slotData.data.getEventDateTime;
            setSlot(slot);
            setParticipantList(slot.currentParticipantsList);
            try {
                const eventData = await client.graphql({
                        query: getEvent,
                        variables: { id: slot.eventID }
                });
                const event = eventData.data.getEvent;
                setEvent(event);
                setEventID(slot.eventID);
            } catch (err) {
                console.log('error fetching events');
            }
        } catch (err) {
            console.log('error fetching slot');
        }
    }

    async function fetchUser(userID) {
        try {
            const userData = await client.graphql({
                query: getUser,
                variables: { id: userID }
            });
            const user = userData.data.getUser;
            setUser(user);
            setUserJoinList(user.joinedEvent);      
        } catch (err) {
          console.log('error fetching user');
          console.error(err)
        }
    }

    async function checkTimeCrash(slot, startTime, endTime, date) {
        try {
            const slotData = await client.graphql({
                    query: getEventDateTime,
                    variables: { id: slot }
            });
            const slots = slotData.data.getEventDateTime;
            if (slots.date === date){
                if ((slots.startTime >= startTime && slots.startTime <= endTime) || (slots.endTime >= startTime && slots.endTime <= endTime)){
                    return true;
                } else {
                    return false;
                }
            } else {
                return false;
            }            
        } catch (err) {
            console.log('error fetching slot');
            console.error(err);
            return true;
        }
    }

    async function handleJoin() {
        try {
            const userData = await client.graphql({
                query: getUser,
                variables: { id: userID }
            });
            const user = userData.data.getUser;
            setUserJoinList(user.joinedEvent);

            try {
                const slotData = await client.graphql({
                        query: getEventDateTime,
                        variables: { id: activityID }
                });
                const slot = slotData.data.getEventDateTime;
                
                if (slot.currentParticipants === slot.maxParticipants) {
                    navigate(`/activity/slotDetail/${activityID}/slotFull`,{state: {activityID}});
                } else if (slot.date < date){
                    navigate(`/activity/slotDetail/${activityID}/slotPass`,{state: {activityID}});
                } else if (slot.date === date && slot.startTime > time){
                    navigate(`/activity/slotDetail/${activityID}/slotPass`,{state: {activityID}});
                }  else {
                    if (user.joinedEvent.length !== 0){
                        let check = false;
                        let crashID = "";
                        for(let i=0;i<user.joinedEvent.length;i++){
                            if(await checkTimeCrash(user.joinedEvent[i], slot.startTime, slot.endTime, slot.date)){
                                check = true;
                                crashID = user.joinedEvent[i];
                            }
                        }
                        if(!check){
                            var newUserJoinList = [...user.joinedEvent, activityID];
                            setUserJoinList(newUserJoinList);
                            updateJoinedEvent(newUserJoinList);
                            var newParticipantList = [...participantList, userID];
                            setParticipantList(newParticipantList);
                            updateSlotParticipants(newParticipantList);
                            navigate(`/activity/slotDetail/${activityID}/joinSuccess`,{state: {activityID}});
                        } else {
                            console.log(crashID)
                            navigate(`/activity/slotDetail/${activityID}/joinFailed`,{state: {activityID, crashID}});
                        }
                    } else {
                        var newUserJoinList = [...user.joinedEvent, activityID];
                        setUserJoinList(newUserJoinList);
                        updateJoinedEvent(newUserJoinList);
                        var newParticipantList = [...participantList, userID];
                        setParticipantList(newParticipantList);
                        updateSlotParticipants(newParticipantList);
                        navigate(`/activity/slotDetail/${activityID}/joinSuccess`,{state: {activityID}});
                    }
                }
            } catch (err) {
                console.log('error fetching slot');
                console.error(err)
            }
            
        } catch (err) {
          console.log('error fetching user');
          console.error(err)
        }
    }

    async function updateJoinedEvent(newJoinedEvent) {
        try {
            const result = await client.graphql({
                query: updateUser,
                variables: {
                  input: {
                    id: userID,
                    joinedEvent: newJoinedEvent
                  }
                }
            });
        } catch (err) {
          console.log(err);
        }
    }

    async function updateSlotParticipants(newCurrentParticipantsList) {
        try {
            const result = await client.graphql({
                query: updateEventDateTime,
                variables: {
                  input: {
                    id: activityID,
                    currentParticipantsList: newCurrentParticipantsList,
                    currentParticipants: newCurrentParticipantsList.length
                  }
                }
            });
        } catch (err) {
          console.log(err);
        }
    }

    const onBack = () => {
        navigate(-1)
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        getSession().then((session) => {
            setUserID(session.accessToken.payload.username);
            setState(true);
            fetchSlot();
        })
        .catch((err) => {
            navigate('/login');
        })
    }, []);

    if (event) {
        var startDateFormat = new Date(slot.date);
        var startMonth = startDateFormat.getMonth();
        var startDateVal = startDateFormat.getDate();
        return (
            <div className="slot-detail">
                <header className="header">
                    <button className="back-button" onClick={onBack}>
                        <img src="\assets\icon\back.svg" alt="" className="back-icon" />
                        <span>返回</span>
                    </button>
                    
                    <button className="join-button" onClick={handleJoin}>參加</button>
                    
                </header>
                <div className="profile-header">
                    <div className="profile-image">
                        <div className="image-placeholder" />
                    </div>
                    <div className="event-stats">
                        <p className="event-name">{event.title}</p>
                        <button className="detail-button" onClick={() => navigate(`/activity/${slot.eventID}`,{state: {id, userID}})}>活動詳情</button>
                    </div>
                </div>
                <div className="list-item">
                    <div className="item-main-info">
                        <div className="item-date">{startDateVal}</div>
                        <div style={{display: "table-cell", width: 170, textAlign: "center"}}>{slot.startTime} - {slot.endTime}</div>
                        <div style={{display: "table-cell", width: 60, textAlign: "center"}}>{slot.currentParticipants}/{slot.maxParticipants}</div>
                    </div>
                    <div className="item-secondary-info">
                        <div className="item-month">{monthNames[startMonth]}</div>
                        <div style={{width: 5}}></div>
                        <div style={{display: "table-cell", marginLeft: 75}}>{slot.place}</div>
                    </div>
                </div>
                <div className="list-item-disabled">
                    <div className="item-main-info">
                        <div className="item-date">備註</div>
                        <div style={{marginLeft: 20}}>{event.remarks}</div>
                    </div>
                </div>
    
                <style jsx>
                {`
                    .slot-detail{
                        width: 100%;
                        min-height: 100vh;
                        background-color: var(--Background-paper-elevation-0, #f2f2f2);
                    }
                    .header {
                        top: 0;
                        position: sticky;
                        z-index: 99;
                        background-color: var(--Background-paper-elevation-0, #f2f2f2);
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding: 12px 16px 12px 8px;
                        font-size: 20px;
                        font-weight: 500;
                        letter-spacing: 0.15px;
                    }
                    .back-button {
                        display: flex;
                        align-items: center;
                        gap: 8px;
                        color: #107970;
                        background: none;
                        border: none;
                        cursor: pointer;
                        font-size: inherit;
                        font-weight: inherit;
                    }
                    .back-icon {
                        width: 16px;
                        height: 20px;
                        object-fit: contain;
                    }
                    .join-button {
                        border-radius: 100px;
                        border: 1px solid #82bbb6;
                        color: #82bbb6;
                        background: none;
                        padding: 4px 12px;
                        font-size: inherit;
                        font-weight: inherit;
                        cursor: pointer;
                    }
                    .profile-header {
                        z-index: 0;
                        display: inline-flex;
                        margin-left: 20px;
                        margin-right: 20px;
                        align-items: flex-end;
                        margin-top: 10px;
                        align-items: center;
                        gap: 10px;
                    }
                    .profile-image {
                        border-radius: 100px;
                        height: 70px;
                        width: 70px;
                        overflow: hidden;
                        align-items: center;
                        justify-content: center;
                    }
                    .image-placeholder {
                        background-color: #d9d9d9;
                        min-height: 70px;
                        width: 70px;
                        background-image: url(/assets/Events/${event.imageUrl});
                        background-size: contain;
                        background-repeat: no-repeat;
                        background-position-y: center;
                        border: 0;
                    }
                    .event-stats {
                        display: inline-flex;
                        align-items: center;
                        gap: 20px;
                    }
                    .event-name {
                        width: 150px;
                        font-family: YuPearl, sans-serif;
                        font-size: 20px;
                        color: var(--text-secondary, rgba(0, 0, 0, 0.6));
                        font-weight: 500;
                        text-wrap: balance;
                        letter-spacing: 0.15px;
                    }
                    .detail-button {
                        width: 100px;
                        border-radius: 100px;
                        border: 1px solid #82bbb6;
                        color: #82bbb6;
                        background: none;
                        padding: 4px 12px;
                        font-size: inherit;
                        font-weight: inherit;
                        cursor: pointer;
                    }
                    .list-item {
                        border-radius: 5px;
                        border: 1px solid rgba(130, 187, 182, 0.5);
                        background-color: #fff;
                        display: flex;
                        margin-top: 20px;
                        margin-left: 20px;
                        margin-right: 20px;
                        width: 90%;
                        flex-direction: column;
                        padding: 16px;
                        color: #107970;
                    }
                    .list-item-disabled {
                        margin-top: 20px;
                        margin-left: 20px;
                        margin-right: 20px;
                        width: 90%;
                        border-radius: 5px;
                        border: 1px solid rgba(130, 187, 182, 0.5);
                        background-color: rgba(0, 0, 0, 0.15);
                        color: rgba(0, 0, 0, 0.6);
                        padding: 16px;
                    }
                    .item-main-info {
                        justify-content: space-evenly;
                        display: table;
                        gap: 20px;
                        font-size: 24px;
                        font-weight: 400;
                        letter-spacing: -1.5px;
                        flex-direction: row;
                    }
                    .item-date {
                        display: table-cell;
                        text-align: center;
                        width: 50px;
                        margin: auto 0;
                    }
                    .item-secondary-info {
                        justify-content: space-evenly;
                        display: table;
                        margin-top: 4px;
                        gap: 55px;
                        font-size: 14px;
                        font-weight: 500;
                        white-space: nowrap;
                        letter-spacing: 0.15px;
                        padding: 1px 0;
                    }
                    .item-month {
                        display: table-cell;
                        text-align: center;
                        width: 55px;
                    }
                `}
                </style>
            </div>
        );
    } else {
        return (
            <div>Loading...</div>
        );
    }
};

export default SlotDetail;
