import Popup from './popup.jsx';
import ProductCard from './product_card/product_card.jsx';
import ProductDetail from './productDetail';

import { useContext, useEffect, useState, useRef } from 'react'
import { useParams, useNavigate, useLocation, Link  } from 'react-router-dom';
import { AccountContext } from '../../Account';

import { generateClient } from 'aws-amplify/api';
import { getProduct } from '../../graphql/queries';
import PurchaseCard from './purchaseCard.jsx';

const Product = (props) => {
    let popupOpened = false;

    const productID = useLocation().state.id;
    const userID = useLocation().state.userID;
    let navigate = useNavigate();

    const [state, setState] = useState(false);
    const { getSession, logout } = useContext(AccountContext);

    const client = generateClient();
    const [product, setProduct] = useState();
    const [visible, setVisible] = useState(false)
    const [backdropClass, setBackdropClass] = useState("activity")

    async function fetchProduct() {
        try {
            const productData = await client.graphql({
                    query: getProduct,
                    variables: { id: productID }
            });
            const product = productData.data.getProduct;
            setProduct(product);
        } catch (err) {
            console.log('error fetching products');
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        getSession().then((session) => {
            setState(true);
            fetchProduct();
        })
        .catch((err) => {
            navigate('/login');
        })
    }, [])
    
    const onBack = () => {
        navigate(-1)
    };

    const popupChange = () => {
        if (!visible){
            setBackdropClass("activity popup-container");
        } else {
            setBackdropClass("activity");
        }
        setVisible(!visible);
    }

    if (product) {
        let isNegative = true;
        var pointsVal = "-" + product.price + "屯圓"
        return (
            <div className={backdropClass}>
                <header className="header">
                    <button className="back-button" onClick={onBack}>
                        <img src="\assets\icon\back.svg" alt="" className="back-icon" />
                        <span>返回</span>
                    </button>
                    <button className="join-button" onClick={popupChange}>換領</button>
                </header>
                <div className="image-section">
                    <div className={`pricing-container ${isNegative ? "pointsTextNegative" : ""}`}>
                        <p className="pricing-text">
                            {pointsVal}
                        </p>
                    </div>
                </div>
                <ProductCard state={JSON.stringify(product)}/>
                <div className="activity-section">
                    
                    <div className="activity-item active">
                        |  更多詳情
                    </div>
                </div>
                <ProductDetail state={product}/>
                <Popup visible={visible} onClose={popupChange} style={{boxShadow: 'rgba(0, 0, 0, 0.2) 0px 0px 0px 1000px', width: '100%', padding: 0, margin: 0, position: 'sticky', bottom: 0, borderRadius: '10px 10px 0px 0px'}}>
                    <PurchaseCard state={product} visible={visible} onClose={popupChange}/>
                </Popup>
                <style jsx>
                {`
                    .activity{
                        width: 100%;
                        min-height: 100vh;
                        background-color: var(--Background-paper-elevation-0, #f2f2f2);
                    }
                    .header {
                        top: 0;
                        position: sticky;
                        z-index: 99;
                        background-color: var(--Background-paper-elevation-0, #f2f2f2);
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding: 12px 16px 12px 8px;
                        font-size: 20px;
                        font-weight: 500;
                        letter-spacing: 0.15px;
                    }
                    .back-button {
                        display: flex;
                        align-items: center;
                        gap: 8px;
                        color: #107970;
                        background: none;
                        border: none;
                        cursor: pointer;
                        font-size: inherit;
                        font-weight: inherit;
                    }
                    .back-icon {
                        width: 16px;
                        height: 20px;
                        object-fit: contain;
                    }
                    .join-button {
                        border-radius: 100px;
                        border: 1px solid #82bbb6;
                        color: #82bbb6;
                        background: none;
                        padding: 4px 12px;
                        font-size: inherit;
                        font-weight: inherit;
                        cursor: pointer;
                    }
                    .image-section {
                        border-radius: 10px;
                        border: 1px solid rgba(0, 0, 0, 0.12);
                        background-color: rgba(0, 0, 0, 0.12);
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        padding: 24px 0 80px;
                        margin: 0px 20px 20px 20px;
                        height: 230px;
                        max-width: 400px;
                        background-image: url(/assets/products/${product.imageUrl});
                        background-size: cover;
                        background-repeat: no-repeat;
                        background-position-y: center;
                        border: 0;
                    }
                    .pricing-container {
                        font-family: YuPearl, sans-serif;
                        border-radius: 0 5px 5px 0;
                        background-color: #107970;
                        padding: 8px 8px 8px 20px;
                        left: 0;
                        position: absolute;
                    }
                    .pointsTextNegative {
                        background-color: #c35540;
                    }
                    .pricing-text {
                        font-size: 20px;
                        color: #fff;
                        font-weight: 500;
                        letter-spacing: 0.15px;
                        margin: 0;
                    }
                    .pricing-unit {
                        font-size: 16px;
                        line-height: 16px;
                    }
                    .activity-section {
                        display: flex;
                        justify-content: flex-start;
                        width: 100%;
                        gap: 10px;
                        padding: 8px 20px;
                        font-size: 20px;
                        font-weight: 500;
                        letter-spacing: 0.15px;
                        font-feature-settings: "clig" off, "liga" off;
                        font-family: YuPearl, sans-serif;
                        max-width: 400px;
                    }
                    .activity-item {
                        white-space: nowrap;
                    }
                    .active {
                        color: #107970;
                    }
                    .inactive {
                        color: var(--text-secondary, rgba(0, 0, 0, 0.6));
                    }
                    .popup-container {
                        backdrop-filter: blur(5px);
                    }
                `}
                </style>
            </div>
        )
    } else {
        return (
            <div>Loading...</div>
        );
    }
};

export default Product;
