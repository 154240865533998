import React from 'react';
import TabButton from './tab_button';
import { useParams, useNavigate, useLocation, Link  } from 'react-router-dom';


const ActivityTabs = () => {
    
    const path = useLocation().pathname;
    const path_array = path.split('/');
    const tabData = [
        { label: '所有活動', type: "activities", list: "all" },
        { label: '賺屯圓活動', type: "activities", list: "earn" },
        { label: '兌換活動', type: "activities", list: "spend" },
        { label: '已儲存活動', type: "activities", list: "save" },
        { label: '所有機構', type: "institutions", list: "all" },
        { label: '已追隨', type: "institutions", list: "follow" },
    ];

    return (
        <nav className="activity-tabs">
            {tabData.map((tab, index) => {
                if (tab.type === path_array[1]){
                    var check = false;
                    if (path_array[2] === tab.list)
                        check = true;
                    return <TabButton key={index} label={tab.label} isActive={check} type={tab.type} list={tab.list} />
                }
            })}
            <style jsx>{`
                .activity-tabs {
                    overflow-x: auto;
                    background-color: var(--Background-paper-elevation-0, #f2f2f2);
                    display: flex;
                    width: 100%;
                    font-size: 20px;
                    color: var(--Primary-main, #82bbb6);
                    font-weight: 500;
                    white-space: nowrap;
                    letter-spacing: 0.15px;
                    padding: 10px 20px;
                }
            `}</style>
        </nav>
    );
};

export default ActivityTabs;