import React, { useState, useContext } from 'react';
import { useParams, useNavigate, useLocation, Link  } from 'react-router-dom';
import { AccountContext } from '../../Account';

const Login = () => {
    
    let navigate = useNavigate();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [warning, setWarning] = useState();

    const { authenticate } = useContext(AccountContext);

    const onSubmit = (event) => {
        if (email === ""){
            setWarning(<p style={{color: 'red'}}>請輸入你的電郵地址!</p>)
        } else {
            if (password === "") {
                setWarning(<p style={{color: 'red'}}>請輸入密碼!</p>)
            } else {
                authenticate(email, password)
                .then((data) => {
                    console.log("Logged in!", data);
                    navigate('/')
                })
                .catch((err) => {
                    console.error("Failed to login!", err);
                    setWarning(<p style={{color: 'red'}}>電郵地址或密碼不正確!</p>)
                })
            }
        }
    }

    return (
        <div className="login">
            <div className="login-form">
                <div className="div-logo" style={{alignSelf: 'center'}}>
                    <img className="img-icon"
                        loading="lazy"
                        src="\assets\icon\logo_horizontal-00.svg"
                    />
                </div>
                <h2 className="login-header">用戶登入</h2>
                <input
                    id="email"
                    type="email"
                    value={email}
                    className="input-field"
                    placeholder="電郵地址"
                    onChange={(event) => setEmail(event.target.value)}
                />
                <input
                    id="password"
                    type="password"
                    value={password}
                    className="input-field"
                    placeholder="密碼"
                    onChange={(event) => setPassword(event.target.value)}
                />
                {warning}                
                <div className="options-row">
                    {/* <div className="remember-password">
                        <input className="checkbox-wrapper" type="radio" id="remember" name="remember" />
                        <span className="remember-text">記住密碼</span>
                    </div> */}
                    <button type="button" className="forgot-password" onClick={() => navigate(`/forgotPassword`)}>忘記密碼</button>
                </div>
                <button onClick={onSubmit} className="login-button">登入</button>
            </div>
            <div className="divider" role="separator" />
            <Link  to={"/signup"} style={{width: "100%", textDecoration: "none", textAlign: "center"}}>
                <button className="register-button">註冊新用戶</button>
            </Link>
            <div className="bottom-indicator" />
            <style jsx>
            {`
                .login {
                    display: flex;
                    max-width: 480px;
                    width: 100%;
                    flex-direction: column;
                    align-items: center;
                    margin: 0 auto;
                    padding: 10px 0;
                    min-height: 100vh;
                    background-color: var(--Background-paper-elevation-0, #f2f2f2);
                }
                .title {
                    color: var(--secondary-main, #ff9800);
                    text-align: center;
                    font-feature-settings: "clig" off, "liga" off;
                    margin-top: 114px;
                    font: 400 48px/108% Caveat Brush, -apple-system, Roboto, Helvetica, sans-serif;
                }
                .login-form {
                    align-self: stretch;
                    display: flex;
                    margin-top: 55px;
                    width: 100%;
                    flex-direction: column;
                    padding: 0 28px;
                }
                .login-header {
                    color: var(--text-primary, rgba(0, 0, 0, 0.87));
                    text-align: center;
                    font-feature-settings: "clig" off, "liga" off;
                    letter-spacing: -1.5px;
                    align-self: center;
                    font: 400 28px YuPearl, sans-serif;
                }
                .input-field {
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 1;
                    font-feature-settings: "clig" off, "liga" off;
                    text-overflow: ellipsis;
                    border-radius: 8px;
                    background-color: #fff;
                    margin-top: 16px;
                    color: var(--text-secondary, rgba(0, 0, 0, 0.6));
                    white-space: nowrap;
                    letter-spacing: 0.15px;
                    padding: 8px 16px;
                    font: 500 20px YuPearl, sans-serif;
                    border: 1px solid #e0e0e0;
                }
                .input-field:first-of-type {
                    margin-top: 24px;
                }
                .options-row {
                    display: flex;
                    margin-top: 8px;
                    width: 100%;
                    gap: 8px;
                    justify-content: flex-end;
                }
                .remember-password {
                    display: flex;
                    align-items: center;
                }
                .checkbox-wrapper {
                    border-radius: 100px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 9px;
                    margin-bottom: 1px;
                    margin-right: 5px;
                }
                .checkbox-icon {
                    aspect-ratio: 1;
                    object-fit: auto;
                    object-position: center;
                    width: 20px;
                }
                .remember-text {
                    color: var(--text-secondary, rgba(0, 0, 0, 0.6));
                    font-feature-settings: "clig" off, "liga" off;
                    letter-spacing: 0.15px;
                    margin: auto 0;
                    font: 500 16px YuPearl, sans-serif;
                }
                .forgot-password {
                    font-feature-settings: "clig" off, "liga" off;
                    border: 0;
                    color: var(--Warning-main, #107970);
                    white-space: nowrap;
                    letter-spacing: 0.15px;
                    margin: auto 0;
                    padding: 4px 5px;
                    font: 500 16px YuPearl, sans-serif;
                }
                .login-button {
                    font-feature-settings: "clig" off, "liga" off;
                    border-radius: 4px;
                    background-color: #107970;
                    margin-top: 24px;
                    color: var(--secondary-contrast, #fff);
                    white-space: nowrap;
                    letter-spacing: 0.15px;
                    padding: 8px 22px;
                    font: 500 20px YuPearl, sans-serif;
                    border: none;
                    cursor: pointer;
                }
                .divider {
                    margin-top: 24px;
                    width: 70%;
                    max-width: 100%;
                    height: 1px;
                    border: 1px solid rgba(0, 0, 0, 0.12);
                }
                .register-button {
                    font-feature-settings: "clig" off, "liga" off;
                    border-radius: 4px;
                    margin-top: 24px;
                    width: 70%;
                    max-width: 337px;
                    color: var(--secondary-main, #107970);
                    white-space: nowrap;
                    letter-spacing: 0.15px;
                    padding: 8px 22px;
                    font: 500 20px YuPearl, sans-serif;
                    border: 1px solid #107970;
                    background: none;
                    cursor: pointer;
                }
                .visually-hidden {
                    position: absolute;
                    width: 1px;
                    height: 1px;
                    padding: 0;
                    margin: -1px;
                    overflow: hidden;
                    clip: rect(0, 0, 0, 0);
                    white-space: nowrap;
                    border-width: 0;
                }
            `}
            </style>
        </div>
    );
};

export default Login;