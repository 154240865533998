import React, { useState, useEffect, Component  } from "react";

const ListItem = ({ date, startTime, endTime, place, maxParticipants, currentParticipants }) => {
    const [itemClass, setItemClass] = useState('list-item');
    const [selected, setSelected] = useState(false);
    const [vacancies, setVacancies] = useState("Full");
    
    const time = startTime + " - " + endTime;
    const startDateFormat = new Date(date);
    const monthIndex = startDateFormat.getMonth();
    const dateVal = startDateFormat.getDate();
    const monthNames = ["一月", "二月", "三月", "四月", "五月", "六月", "七月", "八月", "九月", "十月", "十一月", "十二月"];
    const monthVal = monthNames[monthIndex];
    var vacanciesVal = maxParticipants - currentParticipants
    
    useEffect(() => {
        if (vacanciesVal === 0){
            setItemClass('list-item list-item-disabled');
        } else {
            setVacancies(vacanciesVal)
        }
    })

    const handleClick = () => {
        if (vacancies !== "Full"){
            if (selected === false){
                setSelected(true);
                setItemClass('list-item list-item-selected');
            } else if (selected === true) {
                setSelected(false);
                setItemClass('list-item');
            }
        }
    }

    return (
        <div className={itemClass} onClick={handleClick}>
            <div className="item-main-info">
                <div className="item-date">{dateVal}</div>
                <div style={{display: "table-cell", width: 170, textAlign: "center"}}>{time}</div>
                <div style={{display: "table-cell", width: 60, textAlign: "center"}}>{vacancies}</div>
            </div>
            <div className="item-secondary-info">
                <div className="item-month">{monthVal}</div>
                <div style={{width: 5}}></div>
                <div style={{display: "table-cell", marginLeft: 75}}>{place}</div>
            </div>
            <style jsx>
            {`
                .list-item {
                    border-radius: 5px;
                    border: 1px solid rgba(130, 187, 182, 0.5);
                    background-color: #fff;
                    display: flex;
                    margin-top: 8px;
                    width: 100%;
                    flex-direction: column;
                    padding: 16px;
                }
                .list-item-disabled {
                    border-color: rgba(0, 0, 0, 0.15);
                    border-width: 2px;
                    background-color: rgba(0, 0, 0, 0.15);
                    color: rgba(0, 0, 0, 0.6);
                    padding: 16px;
                }
                .list-item-selected {
                    border-radius: 5px;
                    border: 1px solid rgba(130, 187, 182, 0.5);
                    background-color: #82bbb6;
                    color: #fff;
                    display: flex;
                    margin-top: 8px;
                    width: 100%;
                    flex-direction: column;
                    padding: 16px;
                }
                .item-main-info {
                    justify-content: space-evenly;
                    display: table;
                    gap: 20px;
                    font-size: 24px;
                    font-weight: 400;
                    letter-spacing: -1.5px;
                    flex-direction: row;
                }
                .item-date {
                    display: table-cell;
                    text-align: center;
                    width: 50px;
                    margin: auto 0;
                }
                .item-secondary-info {
                    justify-content: space-evenly;
                    display: table;
                    margin-top: 4px;
                    gap: 55px;
                    font-size: 14px;
                    font-weight: 500;
                    white-space: nowrap;
                    letter-spacing: 0.15px;
                    padding: 1px 0;
                }
                .item-month {
                    display: table-cell;
                    text-align: center;
                    width: 55px;
                }
            `}
            </style>
        </div>
    );
};

export default ListItem;