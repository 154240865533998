import { useContext, useEffect, useState } from 'react'

const Tag = ({ text, index, type, selectedName, setSelectedName }) => {
    
    const [classes, setClasses] = useState("purchase");
    var selected = false;

    // useEffect(() => {
    //     if (index === 0) {
    //         selected = true;
    //         setClasses('selected');
    //         setSelectedName(text);
    //     }
    //     else
    //         selected = false;
    // }, [])

    useEffect(() => {
        if (selectedName !== ""){
            if (selectedName !== text) {
                selected = false;
                setClasses('purchase');
            } else {
                selected = true;
                setClasses('selected');
                setSelectedName(text);
            }
        } else {
            if (index === 0) {
                selected = true;
                setClasses('selected');
                setSelectedName(text);
            } else {
                selected = false;
                setClasses('purchase');
            }
        }
    })

    const handleOnClick = () => {
        if (!selected) {
            setClasses('selected');
            setSelectedName(text);
            selected = true;
        } else {
            setClasses('purchase');
            selected = false;
        }
    }

    if (type){
        type = 'purchase';
        return(
            <div className={`tag tag-${classes}`} onClick={handleOnClick}>
                <p className='text'>{text}</p>
                <style jsx>
                    {`
                        .tag {
                            font-feature-settings: "clig" off, "liga" off;
                            font-family: YuPearl, sans-serif;
                            border-radius: 100px;
                            border: 1px solid;
                            justify-content: center;
                            padding: 3px 4px;
                        }
                        .tag-success {
                            border-color: #82bbb6;
                            color: #82bbb6;
                        }
                        .tag-purchase {
                            height: 30px;
                            border-color: #82bbb6;
                            color: #82bbb6;
                        }
                        .tag-selected {
                            height: 30px;
                            border-color: #107970;
                            background-color: #107970;
                            color: #fff;
                        }
                        .text {
                            margin: 0;
                            padding-left: 20px;
                            padding-right: 20px;
                        }
                    `}
                    </style>
            </div>
        );
    } else {
        type = 'success';
        return(
            <div className={`tag tag-${type}`}>
                {text}
                <style jsx>
                    {`
                        .tag {
                            font-feature-settings: "clig" off, "liga" off;
                            font-family: YuPearl, sans-serif;
                            border-radius: 100px;
                            border: 1px solid;
                            justify-content: center;
                            padding: 3px 4px;
                        }
                        .tag-success {
                            border-color: #82bbb6;
                            color: #82bbb6;
                        }
                        .tag-purchase {
                            border-color: #82bbb6;
                            color: #82bbb6;
                        }
                    `}
                    </style>
            </div>
        );
    }
};

export default Tag;
