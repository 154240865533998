import React, { useEffect, useState } from "react";
import ActivityCard from "./activity_list_card";
import { useParams, useNavigate, useLocation, Link  } from 'react-router-dom';

import { generateClient } from 'aws-amplify/api';
import { listEvents, getUser } from '../../../graphql/queries';

const ActivityList = (userID) => {
    
    const client = generateClient();
    const path = useLocation().pathname;
    const path_array = path.split('/');

    const [events, setEvents] = useState([]);
    const [user, setUser] = useState("");

    const [savedList, setSavedList] = useState([]);

    async function fetchEvents() {
        try {
          const eventData = await client.graphql({
                query: listEvents
          });
          const events = eventData.data.listEvents.items;
          setEvents(events);
        } catch (err) {
          console.log('error fetching events');
        }
    }

    async function fetchUser() {
        try {
            const userData = await client.graphql({
                query: getUser,
                variables: { id: userID.state }
            });
            const user = userData.data.getUser;
            setUser(user);
            setSavedList(user.savedEvent);
        } catch (err) {
          console.log('error fetching user');
        }
    }

    useEffect(() => {
        fetchEvents();
        fetchUser();
    }, []);

    var eventList = events;

    if (path_array[2] === "earn"){
        eventList = events.filter(event => event.type.includes("賺屯圓"));
    } else if (path_array[2] === "spend"){
        eventList = events.filter(event => event.type.includes("兌換"));
    } else if (path_array[2] === "save"){
        eventList = events.filter(event => savedList.includes(event.id));
    }

    if (user){
        const today = new Date();
        var monthVal = today.getMonth() + 1;
        var dayVal = today.getDate();
        if(monthVal < 10){
            monthVal = "0" + monthVal;
        }
        if(dayVal < 10){
            dayVal = "0" + dayVal;
        }
        const date = today.getFullYear() + "-" + monthVal + "-" + dayVal;
        return (
            <div className="list-container">
                <div className="list-wrapper">
                    {eventList.filter(event => event.endDate >= date).sort((a, b) => a.endDate > b.endDate ? 1 : -1).map((event) => (
                        <ActivityCard
                            setSavedList={setSavedList}
                            id={event.id}
                            title={event.title}
                            points={event.points}
                            startDate={event.startDate}
                            endDate={event.endDate}
                            timeSection={event.timeSection}
                            imageUrl={event.imageUrl}
                            userID={userID.state}
                            savedList={savedList}
                            joinedList={user.joinedEvent}
                        />
                    ))}
                </div>
                <style jsx>
                {`
                    .list-container {
                        align-self: stretch;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        padding-bottom: 60px;
                    }
                    .list-wrapper {
                        background-color: #f2f2f2;
                        display: flex;
                        width: 100%;
                        flex-direction: column;
                        padding: 14px 20px 0;
                    }
                `}
                </style>
            </div>
        );
    } else {
        return (
            <div>Loading...</div>
        );
    }
};

export default ActivityList;

