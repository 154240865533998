import React from 'react';
import { useParams, useNavigate, useLocation, Link  } from 'react-router-dom';
import ListItem  from '../common/date_time_location_list/date_time_location_list_item';
import DateTimeLocationList from '../common/date_time_location_list/date_time_location_list';

const DateTimeLocationPicker = () => {

    let navigate = useNavigate();
    
    const onBack = () => {
        navigate(-1)
    };

    return (
        <div className="date-time-location-picker">
            <header className="header">
                <button className="back-button" onClick={onBack}>
                    <img src="\assets\icon\back.svg" alt="" className="back-icon" />
                    <span>返回</span>
                </button>
            </header>
            <div className="date-time-location-list">
                <div className="list-container">
                    <div className="sub-header">
                        <div className="sub-header-item">日期</div>
                        <div className="sub-header-item">時間及地點</div>
                        <div className="sub-header-item sub-header-item-center">空缺</div>
                    </div>
                </div>
                <DateTimeLocationList/>
            </div>
            <div className="footer">
                <button className="participation-button">
                    參加
                </button>
            </div>
            <style jsx>
            {`
                .date-time-location-picker {
                    margin: 0;
                    font-family: YuPearl, sans-serif;
                    width: 100%;
                    min-height: 100vh;
                    background-color: var(--Background-paper-elevation-0, #f2f2f2);
                    padding-bottom: 50px;
                }
                .header {
                    top: 0;
                    position: sticky;
                    z-index: 99;
                    background-color: var(--Background-paper-elevation-0, #f2f2f2);
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 15.5px 16px 12px 8px;
                    font-size: 20px;
                    font-weight: 500;
                    letter-spacing: 0.15px;
                }
                .back-button {
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    color: #107970;
                    background: none;
                    border: none;
                    cursor: pointer;
                    font-size: inherit;
                    font-weight: inherit;
                }
                .back-icon {
                    width: 16px;
                    height: 20px;
                    object-fit: contain;
                }
                .join-button {
                    padding: 4px 12px;
                }
                .visually-hidden {
                    position: absolute;
                    width: 1px;
                    height: 1px;
                    margin: -1px;
                    padding: 0;
                    overflow: hidden;
                    clip: rect(0, 0, 0, 0);
                    white-space: nowrap;
                    border: 0;
                }
                .date-time-location-list {
                    align-self: stretch;
                    display: flex;
                    max-width: 393px;
                    flex-direction: column;
                    color: #107970;
                    justify-content: center;
                    padding-bottom: 30px;
                }
                .list-container {
                    background-color: var(--background-paper-elevation-0);
                    display: flex;
                    width: 100%;
                    flex-direction: column;
                    padding: 8px 20px 0;
                }
                .sub-header {
                    justify-content: space-between;
                    border-radius: 5px;
                    background-color: #82bbb6;
                    display: flex;
                    gap: 10px;
                    font-size: 20px;
                    color: #fff;
                    font-weight: 500;
                    white-space: nowrap;
                    letter-spacing: 0.15px;
                    padding: 10px 16px;
                }
                .sub-header-item {
                    font-feature-settings: "clig" off, "liga" off;
                }
                .sub-header-item-center {
                    text-align: center;
                }
                .participation-button {
                    font-family: YuPearl, sans-serif;
                    justify-content: center;
                    align-items: center;
                    border-radius: 100px;
                    border: 1px solid rgba(130, 187, 182, 1);
                    width: 100%;
                    color: var(--Primary-main, #82bbb6);
                    white-space: nowrap;
                    text-align: center;
                    padding: 4px;
                    background: none;
                    cursor: pointer;
                    font-size: inherit;
                }
                .footer{
                    width: -webkit-fill-available;
                    bottom: 0;
                    position: fixed;
                    background-color: #fff;
                    padding: 10px 20px 10px 20px;
                }
            `}
            </style>
        </div>
    );
};

export default DateTimeLocationPicker;