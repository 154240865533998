import ProductList from '../components/home/product_card_home/product_list_home';
import ActivityList from '../components/common/activity_list/activity_list';
import MyQRCode from '../components/page/my_qr_code'
import { useContext, useEffect, useState } from 'react'
import { useParams, useNavigate, useLocation, Link  } from 'react-router-dom';
import { AccountContext } from '../Account';
import { generateClient } from 'aws-amplify/api';
import { getUser } from '../graphql/queries';

const HomePage = ({setHeader}) => {

    let navigate = useNavigate();
    
    const client = generateClient();

    const [state, setState] = useState(false);
    const [user, setUser] = useState("");
    const [userName, setUserName] = useState();
    const [userID, setUserID] = useState("");
    const [userTuenYuen, setUserTuenYuen] = useState("");
    const { getSession, logout } = useContext(AccountContext);

    async function fetchUser(userID) {
        try {
            const userData = await client.graphql({
                query: getUser,
                variables: { id: userID }
            });
            const user = userData.data.getUser;
            setUser(user);
            setUserTuenYuen(user.totalTuenYuen);
        } catch (err) {
          console.log('error fetching user');
          console.error(err)
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        getSession().then((session) => {
            setUserID(session.accessToken.payload.username);
            setUserName(session.idToken.payload.preferred_username);
            fetchUser(session.accessToken.payload.username);
            setState(true);
        })
        .catch((err) => {
            navigate('/login');
        })
    }, [])

    if (userID){
        return (
            <div className="home">
                <div className="div-container">
                    <div className="div-point">
                        <p className="div-greeting">Hi, {userName}! 你有</p>
                        <div className="div-point-container">
                            <p className="p-point">
                                <span style={{color: "#107970"}}>{userTuenYuen}</span>
                                <img className="img-coin"
                                    loading="lazy"
                                    src="\assets\icon\tuen-dollar.svg"
                                />
                            </p>
                            <div className="div-qrcode">
                                <Link to="/profile/myQRcode">
                                    <img className="img-qrcode"
                                        loading="lazy"
                                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/90872d71e4eec803b789588a736ce26a6475b3e6dc2ae7012e12ea2d8d187b0c?"
                                    />
                                </Link>
                            </div>
                        </div>
                        <img className="img-check"
                            loading="lazy"
                            src="\assets\icon\check_button.svg"
                        />
                    </div>
                    <form className="search-form">
                        <input
                            type="text"
                            id="searchInput"
                            className="search-input"
                            placeholder="尋找"
                            aria-label="尋找"
                        />
                        <button type="submit" className="search-button" aria-label="搜索">
                        <img src="\assets\icon\search.svg" alt="" className="search-icon" />
                        </button>
                    </form>
                    {/* <h1 className="exchange-title">| 屯圓換領物品</h1>
                    <div className="div-product-section">
                        <ProductList/>
                    </div> */}
                    <div className="recommendation-section">
                        <h2 className="recommendation-title">| 為你推薦</h2>
                        <img
                            src="\assets\icon\grid_view.svg"
                            alt="Recommendation icon"
                            className="recommendation-icon"
                        />
                    </div>
                    <ActivityList state={userID}/>
                </div>
                <style jsx>
                {`
                    .home {
                        width: 100%;
                        min-height: 100vh;
                        background-color: var(--Background-paper-elevation-0, #f2f2f2);
                    }
                    .div-container {
                        min-height: 100vh;
                        height: 100%;
                        width: 100%;
                    }
                    .div-point {
                        border-radius: var(--dialog-radius, 10px);
                        border-color: rgba(0, 0, 0, 0.12);
                        border-style: solid;
                        border-width: 1px;
                        background-color: var(--Primary2-contrast, #fff);
                        display: flex;
                        flex-direction: column;
                        padding: 20px 24px;
                        margin: 20px;
                    }
                    .div-greeting {
                        margin: 0;
                        color: var(--Primary-dark, #107970);
                        font-feature-settings: "clig" off, "liga" off;
                        letter-spacing: 0.15px;
                        font: 500 20px YuPearl, sans-serif;
                    }
                    .div-point-container {
                        justify-content: space-between;
                        display: flex;
                        margin-top: 10px;
                        gap: 0px;
                    }
                    .p-point {
                        margin: 0;
                        font-feature-settings: "clig" off, "liga" off;
                        color: var(--Primary-dark, #107970);
                        letter-spacing: -1.5px;
                        justify-content: center;
                        flex: 1;
                        font: 600 76px/116.7% YuPearl, -apple-system, Roboto, Helvetica,
                            sans-serif;
                    }
                    .img-coin {
                        padding-left: 15px;
                        scale: 1.3;
                    }
                    .div-qrcode {
                        align-items: center;
                        border-radius: var(--5, 40px);
                        background-color: var(--Primary-dark, #107970);
                        display: flex;
                        justify-content: center;
                        width: 56px;
                        height: 56px;
                        margin: auto 0;
                        padding: 4px;
                    }
                    .img-qrcode {
                        aspect-ratio: 1;
                        object-fit: auto;
                        object-position: center;
                        width: 32px;
                    }
                    .img-check {
                        display: none;
                        padding-left: 15px;
                        max-height: 40px;
                    }
    
                    .search-form {
                        // display: flex;
                        display: none;
                        align-items: center;
                        gap: 12px;
                        margin: 20px;
                        padding: 8px 8px 8px 20px;
                        border-radius: 8px;
                        border: 1px solid rgba(0, 0, 0, 0.12);
                        background-color: var(--secondary-contrast, #fff);
                        }
                        .search-input {
                        flex: 1;
                        font-family: YuPearl, sans-serif;
                        font-size: 20px;
                        font-weight: 500;
                        color: var(--text-secondary, rgba(0, 0, 0, 0.6));
                        letter-spacing: 0.15px;
                        border: none;
                        background: transparent;
                        outline: none;
                        }
                        .search-input::placeholder {
                        width: 90%;
                        color: var(--text-secondary, rgba(0, 0, 0, 0.6));
                        }
                        .search-button {
                        background: none;
                        border: none;
                        padding: 0;
                        cursor: pointer;
                        }
                        .search-icon {
                        width: 24px;
                        height: 24px;
                        object-fit: contain;
                        }
                        .visually-hidden {
                        position: absolute;
                        width: 1px;
                        height: 1px;
                        padding: 0;
                        margin: -1px;
                        overflow: hidden;
                        clip: rect(0, 0, 0, 0);
                        white-space: nowrap;
                        border: 0;
                        }
                    
                        .exchange-title {
                        color: var(--Primary-dark, #107970);
                        font-feature-settings: "clig" off, "liga" off;
                        letter-spacing: -1.5px;
                        margin: 20px;
                        font: 400 24px YuPearl, sans-serif;
                        }
                        .div-product-section{
                        margin-top: 20px;
                        margin-bottom: 20px;
                        margin-left: 20px;
                        margin-right: 0px;
                        align-self: stretch;
                        overflow-x: auto;
                        display: flex;
                        gap: 8px;
                        }
                        .recommendation-section {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        margin-top: 20px;
                        padding: 8px 8px 8px 0;
                        font-size: 24px;
                        color: var(--Primary-dark, #107970);
                        letter-spacing: -1.5px;
                        }
                        .recommendation-title {
                        color: var(--Primary-dark, #107970);
                        font-feature-settings: "clig" off, "liga" off;
                        letter-spacing: -1.5px;
                        margin: 0;
                        margin-left: 20px;
                        font: 400 24px YuPearl, sans-serif;
                        }
                        .recommendation-icon {
                        aspect-ratio: 1;
                        object-fit: auto;
                        object-position: center;
                        width: 32px;
                        margin-right: 20px;
                        }
                `}
                </style>
            </div>
        );
    } else {
        return (
            <div>Loading...</div>
        );
    }
}
export default HomePage;