/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getTuenYuen = /* GraphQL */ `
  query GetTuenYuen($id: ID!) {
    getTuenYuen(id: $id) {
      id
      eventName
      value
      expireDate
      isExpired
      userID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listTuenYuens = /* GraphQL */ `
  query ListTuenYuens(
    $filter: ModelTuenYuenFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTuenYuens(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        eventName
        value
        expireDate
        isExpired
        userID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const tuenYuensByUserID = /* GraphQL */ `
  query TuenYuensByUserID(
    $userID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelTuenYuenFilterInput
    $limit: Int
    $nextToken: String
  ) {
    tuenYuensByUserID(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventName
        value
        expireDate
        isExpired
        userID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      savedEvent
      joinedEvent
      eventHistory
      savedProduct
      exchangeHistory
      followingOrganization
      TuenYuens {
        nextToken
        __typename
      }
      totalTuenYuen
      relationSanHui
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        savedEvent
        joinedEvent
        eventHistory
        savedProduct
        exchangeHistory
        followingOrganization
        totalTuenYuen
        relationSanHui
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getShop = /* GraphQL */ `
  query GetShop($id: ID!) {
    getShop(id: $id) {
      id
      name
      description
      icon
      Products {
        nextToken
        __typename
      }
      location
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listShops = /* GraphQL */ `
  query ListShops(
    $filter: ModelShopFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listShops(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        icon
        location
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEvent = /* GraphQL */ `
  query GetEvent($id: ID!) {
    getEvent(id: $id) {
      id
      title
      type
      imageUrl
      points
      description
      property
      category
      tags
      remarks
      startDate
      endDate
      timeSection
      place
      maxParticipants
      minParticipants
      organizationID
      EventDateTimes {
        nextToken
        __typename
      }
      organizationName
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listEvents = /* GraphQL */ `
  query ListEvents(
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        type
        imageUrl
        points
        description
        property
        category
        tags
        remarks
        startDate
        endDate
        timeSection
        place
        maxParticipants
        minParticipants
        organizationID
        organizationName
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const eventsByOrganizationID = /* GraphQL */ `
  query EventsByOrganizationID(
    $organizationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventsByOrganizationID(
      organizationID: $organizationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        type
        imageUrl
        points
        description
        property
        category
        tags
        remarks
        startDate
        endDate
        timeSection
        place
        maxParticipants
        minParticipants
        organizationID
        organizationName
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEventDateTime = /* GraphQL */ `
  query GetEventDateTime($id: ID!) {
    getEventDateTime(id: $id) {
      eventID
      id
      eventName
      date
      startTime
      endTime
      place
      maxParticipants
      currentParticipants
      currentParticipantsList
      points
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listEventDateTimes = /* GraphQL */ `
  query ListEventDateTimes(
    $filter: ModelEventDateTimeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEventDateTimes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        eventID
        id
        eventName
        date
        startTime
        endTime
        place
        maxParticipants
        currentParticipants
        currentParticipantsList
        points
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const eventDateTimesByEventID = /* GraphQL */ `
  query EventDateTimesByEventID(
    $eventID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelEventDateTimeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventDateTimesByEventID(
      eventID: $eventID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        eventID
        id
        eventName
        date
        startTime
        endTime
        place
        maxParticipants
        currentParticipants
        currentParticipantsList
        points
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getProduct = /* GraphQL */ `
  query GetProduct($id: ID!) {
    getProduct(id: $id) {
      id
      name
      description
      price
      imageUrl
      shopID
      limit
      remarks
      category
      tags
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listProducts = /* GraphQL */ `
  query ListProducts(
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        price
        imageUrl
        shopID
        limit
        remarks
        category
        tags
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const productsByShopID = /* GraphQL */ `
  query ProductsByShopID(
    $shopID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    productsByShopID(
      shopID: $shopID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        price
        imageUrl
        shopID
        limit
        remarks
        category
        tags
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getOrganization = /* GraphQL */ `
  query GetOrganization($id: ID!) {
    getOrganization(id: $id) {
      id
      name
      description
      icon
      background
      following
      Events {
        nextToken
        __typename
      }
      currentEvents
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listOrganizations = /* GraphQL */ `
  query ListOrganizations(
    $filter: ModelOrganizationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrganizations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        icon
        background
        following
        currentEvents
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
