import React from "react";
import { useContext, useEffect, useState } from 'react'
import { useParams, useNavigate, useLocation, Link  } from 'react-router-dom';
import { generateClient } from 'aws-amplify/api';
import { updateUser } from '../../../graphql/mutations'

const ActivityCard = ( { id, title, points, startDate, endDate, timeSection, imageUrl, userID, savedList, setSavedList, joinedList } ) => {

    let isNegative = false;
    const client = generateClient();
    
    let navigate = useNavigate();

    var startDateFormat = new Date(startDate);
    var startMonth = startDateFormat.getMonth() + 1;
    var startDateVal = startDateFormat.getDate();
    var endDateFormat = new Date(endDate);
    var endMonth = endDateFormat.getMonth() + 1;
    var endDateVal = endDateFormat.getDate();
    
    const [bookmark, setBookmark] = useState("\\assets\\icon\\bookmark_empty.svg");

    useEffect(() => {
        if (savedList.includes(id)){
            setBookmark("\\assets\\icon\\bookmark.svg")
        } else {
            setBookmark("\\assets\\icon\\bookmark_empty.svg")
        }
    })
    

    async function updateSavedList(newSavedList) {
        try {
            const result = await client.graphql({
                query: updateUser,
                variables: {
                  input: {
                    id: userID,
                    savedEvent: newSavedList
                  }
                }
            });
        } catch (err) {
          console.log(err);
        }
    }

    const handleSave = (evt) => { 
        evt.stopPropagation();
        evt.cancelBubble = true;
        
        if (savedList.includes(id)){
            setBookmark("\\assets\\icon\\bookmark_empty.svg")
            var newList = savedList.filter((item) => item !== id);
            setSavedList(newList);
            updateSavedList(newList);
        } else {
            setBookmark("\\assets\\icon\\bookmark.svg");
            var newList = [...savedList, id];
            setSavedList(newList);
            updateSavedList(newList);
        }
    }; 

    if (startMonth < 10)
        startMonth = "0" + startMonth;
    if (startDateVal < 10)
        startDateVal = "0" + startDateVal;
    if (endMonth < 10)
        endMonth = "0" + endMonth;
    if (endDateVal < 10)
        endDateVal = "0" + endDateVal;

    var date = "";
    if (startDate === endDate)
        date = startDateVal + "/" + startMonth;
    else
        date = `${startDateVal}/${startMonth} 至 ${endDateVal}/${endMonth}`

    var time = "";
    if (timeSection.length > 1){
        const allEqual = timeSection => timeSection.every(v => v === timeSection[0]);
        if (allEqual(timeSection))
            time = timeSection[0];
        else
            time = "多個時段";
    }
    else 
        time = timeSection[0];

    if (points < 0)
        isNegative = true;
    else
        points = "+" + points;

    return (
        <div className="activityCard" onClick={() => navigate(`/activity/${id}`,{state: {id, userID, savedList}})}>
            <div className={`pointsWrapper-` + id} >
                <div className={`${"pointsText"} ${isNegative ? "pointsTextNegative" : ""}`}>
                    {points}屯圓<span style={{ fontSize: 14, lineHeight: "16px" }}>/時段</span>
                </div>
            </div>
            <div className="activityInfo">
                <div className="infoWrapper">
                    <div className="textGroup">
                        <div className="activityTitle">{title}</div>
                        <div className="activityDate">{date}</div>
                        <div className="activityTime">{time}</div>
                    </div>
                    <div className="imageWrapper" onClick={handleSave}>
                        <img src={bookmark} alt="" className="image" />
                    </div>
                </div>
                {/* <button className="joinButton">
                    參加
                </button> */}
            </div>
            <style jsx>
            {`
                .activityCard {
                    border-radius: 10px;
                    border-color: rgba(0, 0, 0, 0.12);
                    border-style: solid;
                    border-width: 1px;
                    background-color: #fff;
                    display: flex;
                    gap: 10px;
                    padding: 0px 8px 0px 0px;
                    margin-top: 8px;
                    height: 170px;
                }
                .pointsWrapper-${id} {
                    background-color: #d9d9d9;
                    display: flex;
                    width: 57%;
                    flex-direction: column;
                    font-size: 20px;
                    color: #fff;
                    font-weight: 500;
                    letter-spacing: 0.15px;
                    padding: 12px 0 80px;
                    border-bottom-left-radius: inherit;
                    border-top-left-radius: inherit;
                    max-width: 200px;
                    background-image: url(/assets/Events/${imageUrl});
                    background-size: cover;
                    background-repeat: no-repeat;
                    background-position-x: center;
                    border: 0;
                }
                .pointsText {
                    width: 70%;
                    font-size: 18px;
                    font-family: YuPearl, sans-serif;
                    justify-content: center;
                    border-radius: 0 3px 3px 0;
                    background-color: #107970;
                    padding: 0px 4px;
                }
                .pointsTextNegative {
                    background-color: #c35540;
                }
                .activityInfo {
                    display: flex;
                    flex-direction: column;
                    margin: auto 0;
                    width: 43%;
                }
                .infoWrapper {
                    align-items: start;
                    display: flex;
                    gap: 0px;
                }
                .textGroup {
                    display: flex;
                    margin-top: 8px;
                    padding-bottom: 26px;
                    flex-direction: column;
                    font-size: 16px;
                    color: rgba(0, 0, 0, 0.6);
                    font-weight: 500;
                    letter-spacing: 0.15px;
                    flex: 1;
                }
                .activityTitle {
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 1;
                    font-feature-settings: "clig" off, "liga" off;
                    text-overflow: ellipsis;
                    font: 20px YuPearl, sans-serif;
                }
                .activityDate {
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                    font-feature-settings: "clig" off, "liga" off;
                    text-overflow: ellipsis;
                    font-family: YuPearl, sans-serif;
                    margin-top: 8px;
                }
                .activityTime {
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                    font-feature-settings: "clig" off, "liga" off;
                    text-overflow: ellipsis;
                    font-family: YuPearl, sans-serif;
                    margin-top: 8px;
                }
                .imageWrapper {
                    display: flex;
                    flex-direction: column;
                    overflow: hidden;
                    position: relative;
                    aspect-ratio: 1;
                    width: 24px;
                    align-items: center;
                    justify-content: center;
                    margin-top: 10px;
                }
                .image {
                    position: absolute;
                    inset: 0;
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                    object-position: center;
                }
                .joinButton {
                    font-feature-settings: "clig" off, "liga" off;
                    justify-content: center;
                    border-radius: 100px;
                    border-color: #82bbb6;
                    border-style: solid;
                    border-width: 1px;
                    color: #82bbb6;
                    white-space: nowrap;
                    text-align: center;
                    letter-spacing: 0.15px;
                    padding: 2px;
                    font: 500 20px YuPearl, sans-serif;
                    cursor: pointer;
                    background-color: white;
                    margin-bottom: 8px;
                }
                .visibilityHidden {
                    position: absolute;
                    width: 1px;
                    height: 1px;
                    padding: 0px;
                    margin: -1px;
                    overflow: hidden;
                    clip: rect(0, 0, 0, 0);
                    white-space: nowrap;
                    border-width: 0px;
                }
            `}
            </style>
        </div>
    );
};

export default ActivityCard;