import React, { useContext, useEffect, useState } from 'react'
import { useParams, useNavigate, useLocation  } from 'react-router-dom';
import { AccountContext } from '../../Account';
import { generateClient } from 'aws-amplify/api';
import { getEvent, getEventDateTime } from '../../graphql/queries';

function CancelFail() {

    let navigate = useNavigate();
    const client = generateClient();
    
    const slotID = useLocation().state.id;

    const [slot, setSlot] = useState();
    const [event, setEvent] = useState();
    const [state, setState] = useState(false);
    const { getSession, logout } = useContext(AccountContext);

    async function fetchSlot() {
        try {
            const slotData = await client.graphql({
                    query: getEventDateTime,
                    variables: { id: slotID }
            });
            const slot = slotData.data.getEventDateTime;
            setSlot(slot);
            try {
                const eventData = await client.graphql({
                        query: getEvent,
                        variables: { id: slot.eventID }
                });
                const event = eventData.data.getEvent;
                if (event.points > 0){
                    event.points = "+" + event.points;
                }
                setEvent(event);
            } catch (err) {
                console.log('error fetching events');
            }
        } catch (err) {
            console.log('error fetching slot');
            console.error(err);
        }
    }

    const onBack = () => {
        navigate(-1)
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        getSession().then((session) => {
            setState(true);
            fetchSlot();
        })
        .catch((err) => {
            navigate('/login');
        })
    }, []);

    if(slot && event){

        return (
            <div className="duplicated-timeslot">
                <div className="paper">
                    <header className="dialog-title">
                    <img src="\assets\icon\warning.svg" alt="" className="icon" />
                    <h1 className="heading">取消失敗</h1>
                    </header>
                    <section className="dialog-content">
                    <p className="message">
                        活動開始前24小時不能取消參加活動。
                    </p>
                    <div className={`activity-card`}>
                        <div className="activity-header">
                        <h2 className="activity-title">{event.title}</h2>
                        <span className="activity-points">{event.points}</span>
                        </div>
                        <div className="activity-details">
                        <span className="activity-time">{slot.startTime} - {slot.endTime}</span>
                        <span className="activity-location">{slot.place}</span>
                        </div>
                    </div>
                    </section>
                    <footer className="dialog-actions">
                    <button className="confirm-button" onClick={onBack}>確定</button>
                    </footer>
                </div>
                <style jsx>
                {`
                    .duplicated-timeslot {
                        position: relative;
                        display: flex;
                        max-width: 393px;
                        padding-top: 8px;
                        flex-direction: column;
                        overflow: hidden;
                        align-items: start;
                        font-family: YuPearl, sans-serif;
                        width: 100%;
                        min-height: 100vh;
                        background-color: var(--Background-paper-elevation-0, #f2f2f2);
                    }
                    .paper {
                        border-radius: 10px;
                        background: #fff;
                        box-shadow: 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
                        display: flex;
                        width: 353px;
                        max-width: 100%;
                        flex-direction: column;
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                    }
                    .dialog-title {
                        display: flex;
                        width: 100%;
                        flex-direction: row;
                        font-size: 24px;
                        color: rgba(0, 0, 0, 0.87);
                        font-weight: 400;
                        text-align: center;
                        letter-spacing: -1.5px;
                        justify-content: center;
                        align-items: center;
                        padding: 16px 24px;
                    }
                    .icon {
                        width: 22px;
                        height: 22px;
                        margin-right: 8px;
                    }
                    .heading {
                        font-size: inherit;
                        font-feature-settings: "liga" off, "clig" off;
                        margin: 0;
                    }
                    .dialog-content {
                        display: flex;
                        width: 100%;
                        flex-direction: column;
                        padding: 0 24px 20px;
                    }
                    .message {
                        border-radius: 4px;
                        width: 100%;
                        font-size: 20px;
                        color: rgba(0, 0, 0, 0.6);
                        font-weight: 500;
                        letter-spacing: 0.15px;
                        line-height: 26px;
                        padding: 4px 0;
                        margin: 0 0 8px;
                    }
                    .activity-card {
                        border-radius: 5px;
                        display: flex;
                        min-width: 300px;
                        margin-top: 8px;
                        width: 100%;
                        flex-direction: column;
                        overflow: hidden;
                        padding: 16px;
                    }
                    .activity-card.selected {
                        background-color: #82bbb6;
                        border: 2px solid #82bbb6;
                        color: #fff;
                    }
                    .activity-card:not(.selected) {
                        background-color: #fff;
                        border: 1px solid rgba(130, 187, 182, 0.5);
                        color: #107970;
                    }
                    .activity-header {
                        display: flex;
                        width: 100%;
                        justify-content: space-between;
                        font-size: 24px;
                        font-weight: 400;
                        letter-spacing: -1.5px;
                    }
                    .activity-title {
                        font-size: inherit;
                        font-feature-settings: "liga" off, "clig" off;
                        width: 150px;
                        margin: 0;
                    }
                    .activity-points {
                        text-align: center;
                        font-feature-settings: "liga" off, "clig" off;
                        width: 50px;
                    }
                    .activity-details {
                        display: flex;
                        margin-top: 4px;
                        width: 100%;
                        justify-content: space-between;
                        font-size: 16px;
                        font-weight: 500;
                        letter-spacing: 0.15px;
                    }
                    .activity-time {
                        font-feature-settings: "liga" off, "clig" off;
                        width: 150px;
                    }
                    .activity-location {
                        text-align: center;
                        font-feature-settings: "liga" off, "clig" off;
                    }
                    .dialog-actions {
                        display: flex;
                        width: 100%;
                        align-items: center;
                        font-size: 20px;
                        color: #107970;
                        font-weight: 500;
                        letter-spacing: 0.15px;
                        justify-content: center;
                        padding: 8px;
                    }
                    .confirm-button {
                        border-radius: 4px;
                        display: flex;
                        min-width: 240px;
                        width: 100%;
                        align-items: center;
                        justify-content: center;
                        padding: 6px 8px;
                        background: none;
                        border: none;
                        cursor: pointer;
                        font-feature-settings: "liga" off, "clig" off;
                        font-size: inherit;
                        font-weight: inherit;
                        color: inherit;
                    }
                `}
                </style>
            </div>
        );
    } else {
        return (
            <div>Loading...</div>
        );
    }
}

export default CancelFail;