import { useContext, useEffect, useState } from 'react'
import Tag from '../common/tags/tags';
import { generateClient } from 'aws-amplify/api';
import { getShop } from '../../graphql/queries';

const ProductDetail = (product) => {

    const client = generateClient();
    const [shop, setShop] = useState();

    let proLimit = ""; 
    if (product.state.limit !== "N/A"){
        proLimit = "每人最多換領" + product.state.limit + "件";
    } else {
        proLimit = "N/A";
    }

    async function fetchShop() {
        try {
            const shopData = await client.graphql({
                    query: getShop,
                    variables: { id: product.state.shopID }
            });
            const shop = shopData.data.getShop;
            setShop(shop);
        } catch (err) {
            console.log('error fetching shop');
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        fetchShop();
    }, [])

    if (shop){
        return (
            <>
                <div className="activity-detail">
                    <div className="info-row">
                        <div className="info-label">交收商店</div>
                        <div className="info-value">{shop.name}</div>
                    </div>
                    <div className="info-row">
                        <div className="info-label">交收地址</div>
                        <div className="info-value">{shop.location}</div>
                    </div>
                    <div className="info-row">
                        <div className="info-label">換領限制</div>
                        <div className="info-value">{proLimit}</div>
                    </div>
                    <div className="info-row">
                        <div className="info-label">備註</div>
                        <div className="info-value">{product.state.remarks}</div>
                    </div>
                    <div className="info-row">
                        <div className="info-label">分類</div>
                        <div className="tag-container">
                            {product.state.category.map((tag, index) => (
                                <Tag text={tag} />
                            ))}
                        </div>
                    </div>
                    <div className="info-row">
                        <div className="info-label">標籤</div>
                        <div className="tag-container">
                            {product.state.tags.map((tag, index) => (
                                <Tag text={tag} />
                            ))}
                        </div>
                    </div>
                </div>
                <style>
                {`
                    .activity-detail {
                        align-self: stretch;
                        display: flex;
                        max-width: 353px;
                        flex-direction: column;
                        font-weight: 500;
                        letter-spacing: 0.15px;
                        margin: 0px 20px 20px 20px;
                    }
                    .info-row {
                        justify-content: flex-start;
                        display: flex;
                        margin-top: 10px;
                        padding-right: 20px;
                        gap: 20px;
                        font-size: 20px;
                        color: rgba(0, 0, 0, 0.38);
                        white-space: nowrap;
                    }
                    .info-label {
                        font-feature-settings: "clig" off, "liga" off;
                        text-overflow: ellipsis;
                        font-family: YuPearl, sans-serif;
                        width: 30%;
                    }
                    .info-value {
                        font-feature-settings: "clig" off, "liga" off;
                        text-overflow: ellipsis;
                        font-family: YuPearl, sans-serif;
                        flex: 1;
                        width: 100px;
                        text-wrap: pretty;
                    }
                    .tag-container {
                        overflow: scroll;
                        display: flex;
                        padding-right: 34px;
                        gap: 8px;
                        font-size: 16px;
                        color: var(--success-contrast);
                        text-align: center;
                        flex: 1;
                    } 
                `}
                </style>
            </>
        );
    } else {
        return (
            <div>Loading...</div>
        );
    }
}

export default ProductDetail;
