'use client'
 
import { useContext, useEffect, useState } from 'react'
import { useParams, useNavigate, useLocation, Link  } from 'react-router-dom';
import { AccountContext } from '../Account';
import ActivityList from '../components/common/activity_list/activity_list';
import InstitutionList from "../components/activities/institution_list/institution_list";

const ActivitiesPage = ({path}) => {
    
    let navigate = useNavigate();

    const [state, setState] = useState(false);
    const { getSession, logout } = useContext(AccountContext);
    const [userID, setUserID] = useState("");

    useEffect(() => {
        window.scrollTo(0, 0);
        getSession().then((session) => {
            setUserID(session.accessToken.payload.username);
            setState(true);
        })
        .catch((err) => {
            navigate('/login');
        })
    }, [])

    var list = <ActivityList state={userID}/>;

    if (path === "activities"){
        list = <ActivityList state={userID}/>;
    } else if (path === "institutions"){
        list = <InstitutionList state={userID}/>;
    }
    if (userID){
        return (
            <div className="activity">
                {list}
                <style jsx>
                {`
                    .activity {
                        width: 100%;
                        min-height: 100vh;
                        background-color: var(--Background-paper-elevation-0, #f2f2f2);
                    }
                `}
                </style>
            </div>
        );
    } else {
        return (
            <div>Loading...</div>
        );
    }
}
export default ActivitiesPage;