import { useContext, useEffect, useState } from 'react'
import ListItem from './event_list_item';
import { useParams, useNavigate, useLocation  } from 'react-router-dom';

import { AccountContext } from '../../Account';
import { generateClient } from 'aws-amplify/api';
import { getUser, getEventDateTime, eventsByOrganizationID, listEventDateTimes  } from '../../graphql/queries';

const EventList = (prop) => {
    const client = generateClient();
    let navigate = useNavigate();
    const [state, setState] = useState(false);
    const { getSession, logout } = useContext(AccountContext);

    const [userID, setUserID] = useState("");
    const [joinList, setUserJoinList] = useState("");
    const [savedList, setSavedList] = useState([]);
    const [events, setEvents] = useState([]);

    async function fetchEvents(organizationID) {
        try {
          const eventsData = await client.graphql({
                query: eventsByOrganizationID,
                variables: { organizationID: organizationID },
          });
          const events = eventsData.data.eventsByOrganizationID.items;
          setEvents(events);
        } catch (err) {
          console.log('error fetching todos');
        }
    }

    async function fetchUser2(userID) {
        try {
            const userData = await client.graphql({
                query: getUser,
                variables: { id: userID }
            });
            const user = userData.data.getUser;
            setSavedList(user.savedEvent);
            console.log(savedList)
        } catch (err) {
          console.log('error fetching user');
          console.error(err)
        }
    }

    async function fetchUser(userID) {
        try {
            const userData = await client.graphql({
                query: getUser,
                variables: { id: userID }
            });
            const user = userData.data.getUser;
            setSavedList(user.savedEvent);
            try {
                const eventData = await client.graphql({
                      query: listEventDateTimes
                });
                const events = eventData.data.listEventDateTimes.items;
                setEvents(events.filter(event => user.joinedEvent.includes(event.id)));
            } catch (err) {
            console.log('error fetching events');
            }
        } catch (err) {
          console.log('error fetching user');
          console.error(err)
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        getSession().then((session) => {
            setState(true);
            setUserID(session.accessToken.payload.username);
            if(prop.event){
                fetchEvents(prop.id);
                fetchUser2(session.accessToken.payload.username);
            } else if(prop.mine){
                fetchUser(session.accessToken.payload.username);
            }
        })
        .catch((err) => {
            navigate('/login');
        })
    }, []);

    if(events){
        const today = new Date();
        var monthVal = today.getMonth() + 1;
        var dayVal = today.getDate();
        if(monthVal < 10){
            monthVal = "0" + monthVal;
        }
        if(dayVal < 10){
            dayVal = "0" + dayVal;
        }
        const date = today.getFullYear() + "-" + monthVal + "-" + dayVal;
        if(prop.event){
            var eventList = events;
            if (prop.para === "current"){
                eventList = events.filter(event => event.endDate >= date);
            } else if (prop.para === "history"){
                eventList = events.filter(event => event.endDate < date);
            }
            return (
                <div className="date-time-location-list">
                    <section className="date-time-location-list">
                        <div className="list-container">
                            {eventList.sort((a, b) => a.endDate > b.endDate ? 1 : -1).map((item, index) => (
                                <ListItem
                                    key={index}
                                    date={item.endDate}
                                    title={item.title}
                                    timeSection={item.startDate}
                                    place={item.place}
                                    points={item.points}
                                    type="event"
                                    para={prop.para}
                                    eventID={item.id}
                                    userID={userID}
                                    savedList={savedList}
                                />
                            ))}
                        </div>
                    </section>
                    <style jsx>
                    {`
                        .visually-hidden {
                            position: absolute;
                            width: 1px;
                            height: 1px;
                            margin: -1px;
                            padding: 0;
                            overflow: hidden;
                            clip: rect(0, 0, 0, 0);
                            white-space: nowrap;
                            border: 0;
                        }
                        .date-time-location-list {
                            align-self: stretch;
                            display: flex;
                            max-width: 393px;
                            flex-direction: column;
                            color: #107970;
                            justify-content: center;
                            padding-bottom: 50px;
                        }
                        .list-container {
                            background-color: #f2f2f2;
                            display: flex;
                            width: 100%;
                            flex-direction: column;
                            padding: 8px 20px 0;
                        }
                    `}
                    </style>
                </div>
            );
        } else if (prop.mine){
            var eventList = events;
            if (prop.para === "current"){
                eventList = events.filter(event => event.date >= date);
            } else if (prop.para === "history"){
                eventList = events.filter(event => event.date < date);
            }
            return (
                <div className="date-time-location-list">
                    <section className="date-time-location-list">
                        <div className="list-container">
                            {eventList.sort((a, b) => a.date > b.date ? 1 : -1).map((item, index) => (
                                <ListItem
                                    key={index}
                                    date={item.date}
                                    title={item.eventName}
                                    timeSection={item.startTime + "-" + item.endTime}
                                    place={item.place}
                                    points={item.points}
                                    type="mine"
                                    para={prop.para}
                                    eventID={item.eventID}
                                    userID={userID}
                                    savedList={savedList}
                                />
                            ))}
                        </div>
                    </section>
                    <style jsx>
                    {`
                        .visually-hidden {
                            position: absolute;
                            width: 1px;
                            height: 1px;
                            margin: -1px;
                            padding: 0;
                            overflow: hidden;
                            clip: rect(0, 0, 0, 0);
                            white-space: nowrap;
                            border: 0;
                        }
                        .date-time-location-list {
                            align-self: stretch;
                            display: flex;
                            max-width: 393px;
                            flex-direction: column;
                            color: #107970;
                            justify-content: center;
                            padding-bottom: 50px;
                        }
                        .list-container {
                            background-color: #f2f2f2;
                            display: flex;
                            width: 100%;
                            flex-direction: column;
                            padding: 8px 20px 0;
                        }
                    `}
                    </style>
                </div>
            );
        }
    }
};

export default EventList;