'use client'
 
import { useState } from "react";
import Home from "../../fragment/home";
import Activities from "../../fragment/activities";
import MainHeader from "../header/header_main";
import ActivitiesHeader from "../header/header_activities";
import { Routes, Route, HashRouter, Link, useLocation } from 'react-router-dom';

const MainFooter = ({path}) => {
    if (typeof window !== 'undefined') {

        var home_switch = "\\assets\\icon\\home.svg";
        var activity_switch = "\\assets\\icon\\activity.svg";
        var my_activity_switch = "\\assets\\icon\\my_activity.svg";
        var exchange_goods_switch = "\\assets\\icon\\exchange_goods.svg";

        if (path === "home"){
            home_switch = "\\assets\\icon\\home_on.svg";
        } else if (path === "activities"){
            activity_switch = "\\assets\\icon\\activity_on.svg";
        } else if (path === "my_activities"){
            my_activity_switch = "\\assets\\icon\\my_activity_on.svg";
        } else if (path === "exchange_goods"){
            exchange_goods_switch = "\\assets\\icon\\exchange_goods_on.svg";
        }

        return (
            <nav className="footer">
                <div className="div-footer">
                    <div className="div-footer-button">
                        <Link to="/">
                        <img className="img-footer-button"
                            loading="lazy"
                            src={home_switch}
                        />
                        </Link>
                    </div>
                    <div className="div-footer-button">
                        <Link to="/activities/all">
                        <img className="img-footer-button"
                            loading="lazy"
                            src={activity_switch}
                        />
                        </Link>
                    </div>
                    <div className="div-footer-button">
                        <Link to="/my_activities">
                        <img className="img-footer-button"
                            loading="lazy"
                            src={my_activity_switch}
                        />
                        </Link>
                    </div>
                    <div className="div-footer-button">
                        <Link to="/exchange_goods/all">
                        <img className="img-footer-button"
                            loading="lazy"
                            src={exchange_goods_switch}
                        />
                        </Link>
                    </div>
                  </div>
                <style jsx>
                {`
                    .div-footer {
                        background-color: #ffffff;
                        display: inline-table;
                        width: 100%;
                        bottom: 0;
                        position: fixed;
                        flex-direction: column;
                    }
                    .div-footer-button {
                        scale: 0.85;
                        display: table-cell;
                        width: 25%;
                        text-align: center;
                    }
                    .img-footer-button {
                        object-fit: auto;
                        object-position: center;
                    }
                `}
            </style>
            </nav>
        );
    }
}
export default MainFooter;