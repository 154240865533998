import { useContext, useEffect, useState } from 'react'
import { useParams, useNavigate, useLocation, Link  } from 'react-router-dom';
import { generateClient } from 'aws-amplify/api';
import { updateUser, updateOrganization } from '../../../graphql/mutations'

const InstitutionCard = ({ id, name, icon, currentEvents, following, userID, followedList, setFollowedList, isFollowed }) => {

    const client = generateClient();

    let navigate = useNavigate();

    const [followText, setFollowText] = useState("追隨");
    const [followClass, setFollowClass] = useState("");
    const [follower, setFollower] = useState(following);
    const stats = currentEvents  + "活動 | " + follower + "追隨者";

    useEffect(() => {
        if (isFollowed){
            setFollowText("已追隨");
            setFollowClass(`followed-button-` + id);
        } else {
            setFollowText("追隨");
            setFollowClass(`follow-button-` + id);
        }
    })
    

    async function updateFollowedList(newFollowedList) {
        try {
            const result = await client.graphql({
                query: updateUser,
                variables: {
                  input: {
                    id: userID,
                    followingOrganization: newFollowedList
                  }
                }
            });
        } catch (err) {
          console.log(err);
        }
    }

    async function updateFollower(followerNo) {
        try {
            const result = await client.graphql({
                query: updateOrganization,
                variables: {
                  input: {
                    id: id,
                    following: followerNo
                  }
                }
            });
        } catch (err) {
          console.log(err);
        }
    }

    const handleFollow = (evt) => { 
        evt.stopPropagation();
        evt.cancelBubble = true;
        if (isFollowed){
            setFollowText("追隨");
            setFollowClass(`follow-button-` + id);
            var newList = followedList.filter((item) => item !== id);
            setFollowedList(newList);
            updateFollowedList(newList);
            updateFollower(following-1);
            setFollower(follower-1);
        } else {
            setFollowText("已追隨");
            setFollowClass(`followed-button-` + id);
            var newList = [...followedList, id];
            setFollowedList(newList);
            updateFollowedList(newList);
            updateFollower(following+1);
            setFollower(follower+1);
        }
    }

    return (
        <div className={`institution-card`} onClick={() => navigate(`/institution/${id}`,{state: {id, userID, followedList, isFollowed}})}>
            <div className={`institution-image-` + id } />
            <div className={`institution-details`}>
                <h3 className="institution-name">{name}</h3>
                <p className="institution-stats">{stats}</p>
                <button className={followClass} onClick={handleFollow}>
                    {followText}
                </button>
            </div>
            <style jsx>
            {`
                .institution-card {
                    border-radius: 10px;
                    border: 1px solid rgba(0, 0, 0, 0.12);
                    background-color:  #fff;
                    display: flex;
                    gap: 15px;
                    font-size: 20px;
                    margin-top: 8px;
                }
                .institution-image-${id} {
                    background-color: #d9d9d9;
                    width: 147px;
                    height: 155px;
                    border-radius: 50%;
                    margin: 20px 0px 20px 20px;
                    background-image: url(/assets/Organization/logo/${icon});
                    background-size: cover;
                    background-repeat: round;
                    border: 0;
                }
                .institution-details {
                    display: flex;
                    padding-top: 8px;
                    flex-direction: column;
                    flex: 1;
                    margin: auto 0;
                }
                .institution-name {
                    color: rgba(0, 0, 0, 0.6);
                    font-family: YuPearl, sans-serif;
                    overflow: auto;
                    white-space: break-spaces;
                    margin-bottom: 0px;
                    margin-top: 0px;
                }
                .institution-stats {
                    color: rgba(0, 0, 0, 0.38);
                    font-family: YuPearl, sans-serif;
                    font-size: 16px;
                    margin-top: 8px;
                    margin-left: 3px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    margin-bottom: 20px;
                }
                .follow-button-${id} {
                    font-family: YuPearl, sans-serif;
                    justify-content: center;
                    border-radius: 100px;
                    border: 1.5px solid #82bbb6;
                    color: #82bbb6;
                    white-space: nowrap;
                    text-align: center;
                    padding: 4px;
                    width: 80%;
                    max-width: 300px;
                    background: #fff;
                    font-weight: 1000;
                }
                .followed-button-${id} {
                    background-color: var(--Primary-dark, #107970);
                    color: var(--primary-contrast, #fff);
                    font-family: YuPearl, sans-serif;
                    justify-content: center;
                    border-radius: 100px;
                    border: 1.5px solid #82bbb6;
                    white-space: nowrap;
                    text-align: center;
                    padding: 4px;
                    width: 80%;
                    max-width: 300px;
                    font-weight: 1000;
                }
            `}
            </style>
        </div>
    );
};

export default InstitutionCard;