import { useContext, useEffect, useState } from 'react'
import { useParams, useNavigate, useLocation, Link  } from 'react-router-dom';
import { AccountContext } from '../../Account';
import QRCode from 'qrcode.react';

const MyQRCode = () => {

    let navigate = useNavigate();

    const [state, setState] = useState(false);
    const [imgSrc, setImgSrc] = useState("");
    const [userID, setUserID] = useState("");
    const { getSession, logout } = useContext(AccountContext);

    useEffect(() => {
        window.scrollTo(0, 0);
        getSession().then((session) => {
            setUserID(session.accessToken.payload.username)
            setState(true);
        })
        .catch((err) => {
            navigate('/login');
        })
    }, [])

    return (
        <>
            <div className="qr-code-container">
                <header className="header">
                    <nav className="navigation">
                        <Link to="/" style={{color: "inherit", textDecoration: "none"}}>
                        
                            <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/a94732c5531282fd5423bc07dec694a50beb33d6648c9f713781fa36c034b65f?apiKey=7815785da4f14ff0a9ff881b7330c8c5&" alt="" className="back-icon" />
                            <span className="back-text">返回</span>
                        </Link>
                    </nav>
                </header>
                <div className="qr-code-section">
                    <h1 className="section-title">| 我的二維碼</h1>
                    <div className="qr-code-wrapper">
                        {/* <img loading="lazy" src={`data:image/png;base64,${userID}`} alt="QR Code" className="qr-code-image" /> */}
                        <QRCode style={{height: 300, width: 300}}className="qr-code-image" value={userID} />
                    </div>
                    <p className="warning-text">
                        請勿隨意截圖傳送給他人，你的屯圓或會因此被盜用
                    </p>
                </div>
            </div>
            <style jsx>
            {`
                a{
                    text-decoration: none;
                }
                .qr-code-container {
                    background-color: #fff;
                    display: flex;
                    width: 100%;
                    min-height: 100vh;
                    background-color: var(--Background-paper-elevation-0, #f2f2f2);
                    flex-direction: column;
                    margin: 0 auto;
                }
                .header {
                    align-items: start;
                    background-color: #f2f2f2;
                    display: flex;
                    width: 100%;
                    flex-direction: column;
                    font-size: 20px;
                    color: #107970;
                    font-weight: 500;
                    white-space: nowrap;
                    letter-spacing: 0.15px;
                    justify-content: center;
                    padding: 12px 16px 12px 8px;
                }
                .navigation {
                    justify-content: center;
                    display: flex;
                    gap: 8px;
                }
                .back-icon {
                    aspect-ratio: 0.8;
                    object-fit: auto;
                    object-position: center;
                    width: 16px;
                }
                .back-text {
                    font-feature-settings: "clig" off, "liga" off;
                    font-family: YuPearl, sans-serif;
                    color: #107970;
                    text-decoration: none;
                }
                .qr-code-section {
                    background-color: #f2f2f2;
                    display: flex;
                    width: 100%;
                    flex-direction: column;
                    padding: 16px 20px 80px;
                }
                .section-title {
                    color: #107970;
                    font-feature-settings: "clig" off, "liga" off;
                    letter-spacing: -1.5px;
                    font: 400 24px YuPearl, sans-serif;
                }
                .qr-code-wrapper {
                    justify-content: center;
                    display: flex;
                    margin-top: 10px;
                    flex-direction: column;
                    padding: 51px 10px;
                    max-width: 480px;
                }
                .qr-code-image {
                    aspect-ratio: 1;
                    object-fit: auto;
                    object-position: center;
                    width: 100%;
                    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2), 0 0 2px 0 rgba(0, 0, 0, 0.08), 0 2px 6px 0 rgba(0, 0, 0, 0.1);
                    border: 8px solid #fff;
                    height: 300px;
                    width: 300px;
                }
                .warning-text {
                    color: rgba(0, 0, 0, 0.6);
                    text-align: center;
                    font-feature-settings: "clig" off, "liga" off;
                    letter-spacing: 0.15px;
                    margin: 10px 0 59px;
                    font: 500 20px YuPearl, sans-serif;
                }
            `}
            </style>
        </>
    );
};

export default MyQRCode;