/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createTuenYuen = /* GraphQL */ `
  mutation CreateTuenYuen(
    $input: CreateTuenYuenInput!
    $condition: ModelTuenYuenConditionInput
  ) {
    createTuenYuen(input: $input, condition: $condition) {
      id
      eventName
      value
      expireDate
      isExpired
      userID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateTuenYuen = /* GraphQL */ `
  mutation UpdateTuenYuen(
    $input: UpdateTuenYuenInput!
    $condition: ModelTuenYuenConditionInput
  ) {
    updateTuenYuen(input: $input, condition: $condition) {
      id
      eventName
      value
      expireDate
      isExpired
      userID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteTuenYuen = /* GraphQL */ `
  mutation DeleteTuenYuen(
    $input: DeleteTuenYuenInput!
    $condition: ModelTuenYuenConditionInput
  ) {
    deleteTuenYuen(input: $input, condition: $condition) {
      id
      eventName
      value
      expireDate
      isExpired
      userID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      savedEvent
      joinedEvent
      eventHistory
      savedProduct
      exchangeHistory
      followingOrganization
      TuenYuens {
        nextToken
        __typename
      }
      totalTuenYuen
      relationSanHui
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      savedEvent
      joinedEvent
      eventHistory
      savedProduct
      exchangeHistory
      followingOrganization
      TuenYuens {
        nextToken
        __typename
      }
      totalTuenYuen
      relationSanHui
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      savedEvent
      joinedEvent
      eventHistory
      savedProduct
      exchangeHistory
      followingOrganization
      TuenYuens {
        nextToken
        __typename
      }
      totalTuenYuen
      relationSanHui
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createShop = /* GraphQL */ `
  mutation CreateShop(
    $input: CreateShopInput!
    $condition: ModelShopConditionInput
  ) {
    createShop(input: $input, condition: $condition) {
      id
      name
      description
      icon
      Products {
        nextToken
        __typename
      }
      location
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateShop = /* GraphQL */ `
  mutation UpdateShop(
    $input: UpdateShopInput!
    $condition: ModelShopConditionInput
  ) {
    updateShop(input: $input, condition: $condition) {
      id
      name
      description
      icon
      Products {
        nextToken
        __typename
      }
      location
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteShop = /* GraphQL */ `
  mutation DeleteShop(
    $input: DeleteShopInput!
    $condition: ModelShopConditionInput
  ) {
    deleteShop(input: $input, condition: $condition) {
      id
      name
      description
      icon
      Products {
        nextToken
        __typename
      }
      location
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createEvent = /* GraphQL */ `
  mutation CreateEvent(
    $input: CreateEventInput!
    $condition: ModelEventConditionInput
  ) {
    createEvent(input: $input, condition: $condition) {
      id
      title
      type
      imageUrl
      points
      description
      property
      category
      tags
      remarks
      startDate
      endDate
      timeSection
      place
      maxParticipants
      minParticipants
      organizationID
      EventDateTimes {
        nextToken
        __typename
      }
      organizationName
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateEvent = /* GraphQL */ `
  mutation UpdateEvent(
    $input: UpdateEventInput!
    $condition: ModelEventConditionInput
  ) {
    updateEvent(input: $input, condition: $condition) {
      id
      title
      type
      imageUrl
      points
      description
      property
      category
      tags
      remarks
      startDate
      endDate
      timeSection
      place
      maxParticipants
      minParticipants
      organizationID
      EventDateTimes {
        nextToken
        __typename
      }
      organizationName
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteEvent = /* GraphQL */ `
  mutation DeleteEvent(
    $input: DeleteEventInput!
    $condition: ModelEventConditionInput
  ) {
    deleteEvent(input: $input, condition: $condition) {
      id
      title
      type
      imageUrl
      points
      description
      property
      category
      tags
      remarks
      startDate
      endDate
      timeSection
      place
      maxParticipants
      minParticipants
      organizationID
      EventDateTimes {
        nextToken
        __typename
      }
      organizationName
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createEventDateTime = /* GraphQL */ `
  mutation CreateEventDateTime(
    $input: CreateEventDateTimeInput!
    $condition: ModelEventDateTimeConditionInput
  ) {
    createEventDateTime(input: $input, condition: $condition) {
      eventID
      id
      eventName
      date
      startTime
      endTime
      place
      maxParticipants
      currentParticipants
      currentParticipantsList
      points
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateEventDateTime = /* GraphQL */ `
  mutation UpdateEventDateTime(
    $input: UpdateEventDateTimeInput!
    $condition: ModelEventDateTimeConditionInput
  ) {
    updateEventDateTime(input: $input, condition: $condition) {
      eventID
      id
      eventName
      date
      startTime
      endTime
      place
      maxParticipants
      currentParticipants
      currentParticipantsList
      points
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteEventDateTime = /* GraphQL */ `
  mutation DeleteEventDateTime(
    $input: DeleteEventDateTimeInput!
    $condition: ModelEventDateTimeConditionInput
  ) {
    deleteEventDateTime(input: $input, condition: $condition) {
      eventID
      id
      eventName
      date
      startTime
      endTime
      place
      maxParticipants
      currentParticipants
      currentParticipantsList
      points
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createProduct = /* GraphQL */ `
  mutation CreateProduct(
    $input: CreateProductInput!
    $condition: ModelProductConditionInput
  ) {
    createProduct(input: $input, condition: $condition) {
      id
      name
      description
      price
      imageUrl
      shopID
      limit
      remarks
      category
      tags
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateProduct = /* GraphQL */ `
  mutation UpdateProduct(
    $input: UpdateProductInput!
    $condition: ModelProductConditionInput
  ) {
    updateProduct(input: $input, condition: $condition) {
      id
      name
      description
      price
      imageUrl
      shopID
      limit
      remarks
      category
      tags
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteProduct = /* GraphQL */ `
  mutation DeleteProduct(
    $input: DeleteProductInput!
    $condition: ModelProductConditionInput
  ) {
    deleteProduct(input: $input, condition: $condition) {
      id
      name
      description
      price
      imageUrl
      shopID
      limit
      remarks
      category
      tags
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createOrganization = /* GraphQL */ `
  mutation CreateOrganization(
    $input: CreateOrganizationInput!
    $condition: ModelOrganizationConditionInput
  ) {
    createOrganization(input: $input, condition: $condition) {
      id
      name
      description
      icon
      background
      following
      Events {
        nextToken
        __typename
      }
      currentEvents
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateOrganization = /* GraphQL */ `
  mutation UpdateOrganization(
    $input: UpdateOrganizationInput!
    $condition: ModelOrganizationConditionInput
  ) {
    updateOrganization(input: $input, condition: $condition) {
      id
      name
      description
      icon
      background
      following
      Events {
        nextToken
        __typename
      }
      currentEvents
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteOrganization = /* GraphQL */ `
  mutation DeleteOrganization(
    $input: DeleteOrganizationInput!
    $condition: ModelOrganizationConditionInput
  ) {
    deleteOrganization(input: $input, condition: $condition) {
      id
      name
      description
      icon
      background
      following
      Events {
        nextToken
        __typename
      }
      currentEvents
      createdAt
      updatedAt
      __typename
    }
  }
`;
