import React from 'react';
import ProductDescription from './product_description.jsx';
import { useParams, useNavigate, useLocation, Link  } from 'react-router-dom';

const ProductCard = (product) => {
    product = JSON.parse(product.state);

    return (
        <article className="product-card">
            <header className="product-header">
                <h3 className="product-title">{product.name}</h3>
                <img src="\assets\icon\bookmark_empty.svg" alt="" className="product-icon" />
            </header>
            <ProductDescription state={JSON.stringify(product.description)}/>
            <style jsx>
            {`
                .product-card {
                    display: flex;
                    flex-direction: column;
                    max-width: 353px;
                    font-size: 20px;
                    color: var(--text-secondary, rgba(0, 0, 0, 0.6));
                    font-weight: 500;
                    letter-spacing: 0.15px;
                    margin: 20px 20px 0px 20px;
                }
                .product-header {
                    display: flex;
                    gap: 8px;
                    font-size: 28px;
                    color: var(--text-primary, rgba(0, 0, 0, 0.87));
                    font-weight: 400;
                    white-space: nowrap;
                    letter-spacing: -1.5px;
                }
                .product-title {
                    font-family: YuPearl, sans-serif;
                    flex: 1;
                    margin: auto 0;
                    white-space: normal;
                }
                .product-icon {
                    width: 32px;
                    height: 32px;
                    object-fit: contain;
                }
                .product-info {
                    display: flex;
                    margin-top: 10px;
                    padding-right: 20px;
                    gap: 8px;
                }
                .info-icon {
                    width: 24px;
                    height: 24px;
                    object-fit: contain;
                }
                .info-text {
                    font-family: YuPearl, sans-serif;
                    margin: auto 0;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                }
                .participation-button {
                    font-family: YuPearl, sans-serif;
                    justify-content: center;
                    align-items: center;
                    border-radius: 100px;
                    border: 1px solid rgba(130, 187, 182, 1);
                    width: 100%;
                    color: var(--Primary-main, #82bbb6);
                    white-space: nowrap;
                    text-align: center;
                    padding: 4px;
                    background: none;
                    cursor: pointer;
                    font-size: inherit;
                }
            `}
            </style>
        </article>
    );
};

export default ProductCard;