import React, { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation, Link  } from 'react-router-dom';
import InstitutionCard from './institution_card';

import { generateClient } from 'aws-amplify/api';
import { listOrganizations, getUser } from '../../../graphql/queries';

const InstitutionList = (userID) => {

    const client = generateClient();

    const path = useLocation().pathname;
    const path_array = path.split('/');

    const [organizations, setOrganizations] = useState([]);
    const [user, setUser] = useState();
    const [followedList, setFollowedList] = useState([]);

    async function fetchOrganizations() {
        try {
          const organizationData = await client.graphql({
                query: listOrganizations
          });
          const organizations = organizationData.data.listOrganizations.items;
          setOrganizations(organizations);
        } catch (err) {
          console.log('error fetching todos');
        }
    }

    async function fetchUser() {
        try {
            const userData = await client.graphql({
                query: getUser,
                variables: { id: userID.state }
            });
            const user = userData.data.getUser;
            setUser(user);
            setFollowedList(user.followingOrganization);
        } catch (err) {
          console.log('error fetching user');
        }
    }

    useEffect(() => {
        fetchOrganizations();
        fetchUser();
    }, []);

    var organizationsList = organizations;

    if (path_array[2] === "follow"){
        organizationsList = organizations.filter(organization => followedList.includes(organization.id));
    }

    return (
        <>
            <section className="institution-list">
                <div className="list-container">
                    {organizationsList.sort((a, b) => a.name > b.name ? 1 : -1).map((org, index) => (
                        <InstitutionCard
                            setFollowedList={setFollowedList}
                            id={org.id}
                            name={org.name}
                            icon={org.icon}
                            currentEvents={org.currentEvents}
                            following={org.following}
                            userID={userID.state}
                            followedList={followedList}
                            isFollowed={followedList.includes(org.id)}
                        />
                    ))}
                </div>
            </section>
            <style jsx>
            {`
                .organization-list {
                    align-self: stretch;
                    display: flex;
                    max-width: 393px;
                    flex-direction: column;
                    font-weight: 500;
                    letter-spacing: 0.15px;
                    justify-content: center;
                }
                .list-container {
                    background-color: #f2f2f2;
                    display: flex;
                    width: 100%;
                    flex-direction: column;
                    padding: 14px 20px 60px;
                }
            `}
            </style>
        </>
    );
};

export default InstitutionList;