import React, { useState, useEffect, Component, useContext  } from "react";
import { useParams, useNavigate, useLocation, Link  } from 'react-router-dom';
import { generateClient } from 'aws-amplify/api';
import { getEvent, getEventDateTime, getUser } from '../../graphql/queries';
import { updateUser, updateEventDateTime } from '../../graphql/mutations';
import { AccountContext } from '../../Account';

const SlotItem = ({ id, date, startTime, endTime, place, maxParticipants, currentParticipants, currentParticipantsList, userJoinList}) => {

    let navigate = useNavigate();
    const client = generateClient();

    const [state, setState] = useState(false);
    const [userID, setUserID] = useState("");
    const [selected, setSelected] = useState(false);
    const [onclick, setOnclick] = useState("");
    const [itemClass, setItemClass] = useState('list-item');
    const [vacancies, setVacancies] = useState("");
    const [button, setButton] = useState("");
    const [width, setWidth] = useState("");
    const { getSession, logout } = useContext(AccountContext);
    
    const time = startTime + " - " + endTime;
    const startDateFormat = new Date(date);
    const monthIndex = startDateFormat.getMonth();
    const dateVal = startDateFormat.getDate();
    const monthNames = ["一月", "二月", "三月", "四月", "五月", "六月", "七月", "八月", "九月", "十月", "十一月", "十二月"];
    const monthVal = monthNames[monthIndex];
    var vacanciesVal = maxParticipants - currentParticipants
    
    async function fetchSlot(userID) {
        try {
            const slotData = await client.graphql({
                    query: getEventDateTime,
                    variables: { id: id }
            });
            const slot = slotData.data.getEventDateTime;
            var newUserJoinList = slot.currentParticipantsList.filter((item) => item !== userID);
            try {
                const result = await client.graphql({
                    query: updateEventDateTime,
                    variables: {
                        input: {
                            id: id,
                            currentParticipantsList: newUserJoinList,
                            currentParticipants: newUserJoinList.length
                        }
                    }
                });
            } catch (err) {
                console.log(err);
            }
        } catch (err) {
            console.log('error fetching slot');
        }
    }

    async function fetchUser(userID) {
        try {
            const userData = await client.graphql({
                query: getUser,
                variables: { id: userID }
            });
            const user = userData.data.getUser;
            var newUserJoinList = user.joinedEvent.filter((item) => item !== id);
            try {
                const result = await client.graphql({
                    query: updateUser,
                    variables: {
                        input: {
                            id: userID,
                            joinedEvent: newUserJoinList
                        }
                    }
                });
            } catch (err) {
                console.log(err);
            }
        } catch (err) {
          console.log('error fetching user');
          console.error(err)
        }
    }


    const handleCancel = (evt) => {
        evt.stopPropagation();
        evt.cancelBubble = true;

        //if 1days early -> cant cancel
        const today = new Date();
        var deadlineDate = new Date(date);
        startDateFormat.setHours(startTime.substring(0, 2));
        startDateFormat.setMinutes(startTime.substring(3));
        deadlineDate.setDate(startDateFormat.getDate() - 1);
        deadlineDate.setHours(startTime.substring(0, 2));
        deadlineDate.setMinutes(startTime.substring(3));
        if (today < startDateFormat){
            if (today < deadlineDate){
                fetchSlot(userID);
                fetchUser(userID);
                navigate(`/activity/slotDetail/${id}/cancelSuccess`,{state: {id}});
            } else {
                navigate(`/activity/slotDetail/${id}/cancelFailed`,{state: {id}});
            }
        } else {
            console.log("The event is overed")
        }
    }

    const handleOnClick = () => {
        if (vacanciesVal === 0){

        } else {
            navigate(`/activity/slotDetail/${id}`,{state: {id}});
        }
    }

    useEffect(() => {
        getSession().then((session) => {
            setUserID(session.accessToken.payload.username);
            setState(true);
            if (userJoinList.includes(id)){
                setWidth(0);
                setItemClass('list-item list-item-joined');
                setButton(
                    <button className="cancel-button" onClick={handleCancel}>
                        <span className="cancel-text">取消參加</span>
                    </button>
                )
            } else {
                setWidth(60);
                if (vacanciesVal === 0){
                    setItemClass('list-item list-item-disabled');
                    setVacancies("Full")
                } else {
                    setVacancies(currentParticipants + "/" + maxParticipants)
                }
            }
            })
            .catch((err) => {
                navigate('/login');
            })
    })

    return (
        <div className={itemClass} onClick={handleOnClick}>
            <div className="item-main-info">
                <div className="item-date">{dateVal}</div>
                <div style={{display: "table-cell", width: 170, textAlign: "center"}}>{time}</div>
                <div style={{display: "table-cell", width: {width}, textAlign: "center"}}>{vacancies}</div>
                {button}
            </div>
            <div className="item-secondary-info">
                <div className="item-month">{monthVal}</div>
                <div style={{width: 5}}></div>
                <div style={{display: "table-cell", marginLeft: 75}}>{place}</div>
            </div>
            <style jsx>
            {`
                .list-item {
                    border-radius: 5px;
                    border: 1px solid rgba(130, 187, 182, 0.5);
                    background-color: #fff;
                    display: flex;
                    margin-top: 8px;
                    width: 100%;
                    flex-direction: column;
                    padding: 16px;
                }
                .list-item-disabled {
                    border-color: rgba(0, 0, 0, 0.15);
                    border-width: 2px;
                    background-color: rgba(0, 0, 0, 0.15);
                    color: rgba(0, 0, 0, 0.6);
                    padding: 16px;
                }
                .list-item-selected {
                    border-radius: 5px;
                    border: 1px solid rgba(130, 187, 182, 0.5);
                    background-color: #82bbb6;
                    color: #fff;
                    display: flex;
                    margin-top: 8px;
                    width: 100%;
                    flex-direction: column;
                    padding: 16px;
                }
                .list-item-joined {
                    border-color: rgba(16, 121, 112, 1);
                    border-width: 2px;
                    background-color: rgba(16, 121, 112, 1);
                    color: #fff;
                    padding: 16px;
                }
                .item-main-info {
                    justify-content: space-evenly;
                    display: table;
                    gap: 20px;
                    font-size: 24px;
                    font-weight: 400;
                    letter-spacing: -1.5px;
                    flex-direction: row;
                }
                .item-date {
                    display: table-cell;
                    text-align: center;
                    width: 50px;
                    margin: auto 0;
                }
                .item-secondary-info {
                    justify-content: space-evenly;
                    display: table;
                    margin-top: 4px;
                    gap: 55px;
                    font-size: 14px;
                    font-weight: 500;
                    white-space: nowrap;
                    letter-spacing: 0.15px;
                    padding: 1px 0;
                }
                .item-month {
                    display: table-cell;
                    text-align: center;
                    width: 55px;
                }
                .cancel-button {
                    width: 70px;
                    height: 70px;
                    text-align: center;
                    border-radius: 150px;
                    background-color: rgba(211, 47, 47, 1);
                    color: var(--error-contrast, #fff);
                    padding: 8px 4px;
                    border: none;
                    margin-bottom: -40px;
                    margin-top: -5px;
                    margin-left: 10px;
                }
                .cancel-text {
                    font-size: 12px;
                    font-feature-settings: "liga" off, "clig" off;
                    padding: 3px 6px;
                }
            `}
            </style>
        </div>
    );
};

export default SlotItem;