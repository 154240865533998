import { useContext, useEffect, useState } from 'react'
import EventDescription from './event_description';
import { useParams, useNavigate, useLocation, Link  } from 'react-router-dom';
import { generateClient } from 'aws-amplify/api';
import { updateUser } from '../../../graphql/mutations'

const EventCard = (state) => {

    const [bookmark, setBookmark] = useState("\\assets\\icon\\bookmark_empty.svg");
    const [savedList, setSavedList] = useState(state.savedList);
    const client = generateClient();

    const event = state.state;
    const userID = state.userID;

    var date = "";
    if (event.startDate === event.endDate)
        date = event.startDate;
    else
        date = event.startDate + " - " + event.endDate;

    var place = ""
    for(var i=0; i < event.place.length; i++){
        if (i !== event.place.length - 1)
            place += event.place[i] + ", ";
        else 
            place += event.place[i];
    }

    var time = "";
    var timeList = event.timeSection;
    if (event.timeSection.length > 1){
        const allEqual = timeList => timeList.every(v => v === timeList[0]);
        if (allEqual(timeList))
            time = event.timeSection[0];
        else
            time = "多個時段";
    }
    else 
        time = event.timeSection[0];

    var description  = event.description;

    async function updateSavedList(newSavedList) {
        try {
            const result = await client.graphql({
                query: updateUser,
                variables: {
                  input: {
                    id: userID,
                    savedEvent: newSavedList
                  }
                }
            });
        } catch (err) {
          console.log(err);
        }
    }

    const handleSave = (evt) => { 
        evt.stopPropagation();
        evt.cancelBubble = true;
        
        if (savedList.includes(event.id)){
            setBookmark("\\assets\\icon\\bookmark_empty.svg")
            var newList = savedList.filter((item) => item !== event.id);
            setSavedList(newList);
            updateSavedList(newList);
        } else {
            setBookmark("\\assets\\icon\\bookmark.svg");
            var newList = [...savedList, event.id];
            setSavedList(newList);
            updateSavedList(newList);
        }
    }; 

    useEffect(() => {
        if (savedList.includes(event.id)){
            setBookmark("\\assets\\icon\\bookmark.svg")
        } else {
            setBookmark("\\assets\\icon\\bookmark_empty.svg")
        }
    })

    return (
        <article className="event-card">
            <header className="event-header">
                <h3 className="event-title">{event.title}</h3>
                <img src={bookmark} alt="" className="event-icon" onClick={handleSave}/>
            </header>
            <div className="event-info">
                <img src="\assets\icon\calendar.svg" alt="" className="info-icon" />
                <p className="info-text">{date}</p>
            </div>
            <div className="event-info">
                <img src="\assets\icon\clock.svg" alt="" className="info-icon" />
                <p className="info-text">{time}</p>
            </div>
            <div className="event-info">
                <img src="\assets\icon\calendar.svg" alt="" className="info-icon" />
                <p className="info-text">{place}</p>
            </div>
            <EventDescription state={JSON.stringify(description)}/>
            <style jsx>
            {`
                .event-card {
                    display: flex;
                    flex-direction: column;
                    max-width: 353px;
                    font-size: 20px;
                    color: var(--text-secondary, rgba(0, 0, 0, 0.6));
                    font-weight: 500;
                    letter-spacing: 0.15px;
                    margin: 20px 20px 0px 20px;
                }
                .event-header {
                    display: flex;
                    gap: 8px;
                    font-size: 28px;
                    color: var(--text-primary, rgba(0, 0, 0, 0.87));
                    font-weight: 400;
                    white-space: nowrap;
                    letter-spacing: -1.5px;
                }
                .event-title {
                    font-family: YuPearl, sans-serif;
                    flex: 1;
                    margin: auto 0;
                    white-space: normal;
                }
                .event-icon {
                    width: 32px;
                    height: 32px;
                    object-fit: contain;
                }
                    .event-info {
                    display: flex;
                    margin-top: 10px;
                    padding-right: 20px;
                    gap: 8px;
                }
                .info-icon {
                    width: 24px;
                    height: 24px;
                    object-fit: contain;
                }
                .info-text {
                    font-family: YuPearl, sans-serif;
                    margin: auto 0;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                }
                .participation-button {
                    font-family: YuPearl, sans-serif;
                    justify-content: center;
                    align-items: center;
                    border-radius: 100px;
                    border: 1px solid rgba(130, 187, 182, 1);
                    width: 100%;
                    color: var(--Primary-main, #82bbb6);
                    white-space: nowrap;
                    text-align: center;
                    padding: 4px;
                    background: none;
                    cursor: pointer;
                    font-size: inherit;
                }
            `}
            </style>
        </article>
    );
};

export default EventCard;