import React, { useEffect, useState } from "react";
import ListItem from './date_time_location_list_item';
import { useParams, useNavigate, useLocation  } from 'react-router-dom';

import { generateClient } from 'aws-amplify/api';
import { eventDateTimesByEventID  } from '../../../graphql/queries';

const DateTimeLocationList = (props) => {
    
    const activityID = useLocation().state.id;

    const client = generateClient();
    
    const [eventDateTimes, setEventDateTimes] = useState([]);

    const [selectedList, setSelectedList] = useState([]);

    useEffect(() => {
        fetchEventDateTimes();
    }, []);

    async function fetchEventDateTimes() {
        try {
          const eventDateTimesData = await client.graphql({
                query: eventDateTimesByEventID,
                variables: { eventID: activityID },
          });
          const eventDateTimes = eventDateTimesData.data.eventDateTimesByEventID.items;
          setEventDateTimes(eventDateTimes);
        } catch (err) {
          console.log('error fetching todos');
        }
    }

    const today = new Date();
    var monthVal = today.getMonth() + 1;
    var dayVal = today.getDate();
    if(monthVal < 10){
        monthVal = "0" + monthVal;
    }
    if(dayVal < 10){
        dayVal = "0" + dayVal;
    }

    const date = today.getFullYear() + "-" + monthVal + "-" + dayVal;

    return (
        <div className="date-time-location-list">
            <section className="date-time-location-list">
                <div className="list-container">
                    {eventDateTimes.filter(event => event.date >= date).sort((a, b) => a.date > b.date ? 1 : -1).map((item, index) => (
                        <ListItem
                            key={index}
                            date={item.date}
                            startTime={item.startTime}
                            endTime={item.endTime}
                            place={item.place}
                            maxParticipants={item.maxParticipants}
                            currentParticipants={item.currentParticipants}
                         />
                    ))}
                </div>
            </section>
            <style jsx>
            {`
                .visually-hidden {
                    position: absolute;
                    width: 1px;
                    height: 1px;
                    margin: -1px;
                    padding: 0;
                    overflow: hidden;
                    clip: rect(0, 0, 0, 0);
                    white-space: nowrap;
                    border: 0;
                }
                .date-time-location-list {
                    align-self: stretch;
                    display: flex;
                    max-width: 393px;
                    flex-direction: column;
                    color: #107970;
                    justify-content: center;
                    padding-bottom: 50px;
                }
                .list-container {
                    background-color: #f2f2f2;
                    display: flex;
                    width: 100%;
                    flex-direction: column;
                    padding: 8px 20px 0;
                }
            `}
            </style>
        </div>
    );
};

export default DateTimeLocationList;