import React, { useState } from 'react';

const UpComing = () => {

    return (
        <div className="container">
            
            敬請期待!

            <style jsx>
            {`
                .container {
                    display: flex;
                    max-width: 480px;
                    width: 100%;
                    flex-direction: column;
                    align-items: center;
                    margin: 0 auto;
                    padding: 10px 0;
                    min-height: 100vh;
                    background-color: var(--Background-paper-elevation-0, #f2f2f2);
                    justify-content: center;
                    color: #107970;
                    font-size: xx-large;
                }
            `}
            </style>
        </div>
    );
};

export default UpComing;
