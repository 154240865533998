import { useContext, useEffect, useState } from 'react'
import Tag from '../common/tags/tags';

const PurchaseCard = (product) => {
    const [count, setCount] = useState(0)
    const [selectedName, setSelectedName] = useState("")

    let max = 100;
    function handleClickAdd() {
        if (product.state.limit !== "N/A"){
            if (count < parseInt(product.state.limit , 10 )) {
                setCount(count + 1)
                max = parseInt(product.state.limit , 10 );
            }
        } else {
            setCount(count + 1)
        }
    }

    function handleClickSubtract() {
        if (count > 0) {
            setCount(count - 1)
        }
    }

    function handleClick(e) {
        setCount(e.target.valueAsNumber)
    }

    return(
        <div className='purchase-card'>
            <header className="p-header">
                <button className="p-back-button" onClick={product.onClose}>
                    <img src="\assets\icon\back.svg" alt="" className="p-back-icon" />
                    <span>返回</span>
                </button>
            </header>
            <div className='category-card'>
                {product.state.category.map((tag, index, type = "purchase") => (
                    <Tag text={tag} type={type} index={index} selectedName={selectedName} setSelectedName={setSelectedName} />
                ))}
            </div>
            <div className="counter">
                <input
                    className="counter-input"
                    type="number"
                    min={0}
                    max={max}
                    value={count}
                    width="80px"
                    onChange={handleClick}
                />
                <div counter onClick={handleClickAdd} className="counter-add">+</div>
                <div counter onClick={handleClickSubtract} className="counter-minus">-</div>
            </div>
            <div className="purchase">確定換領</div>
            <style jsx>
            {`
                .tag-success {
                    border-color: #82bbb6;
                    color: #82bbb6;
                }
                .purchase-card {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    align-content: space-around;
                    flex-wrap: wrap;
                    padding: 20px;
                }
                .p-header {
                    top: 0;
                    position: sticky;
                    z-index: 99;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 12px 0px 12px 0px;
                    font-size: 20px;
                    font-weight: 500;
                    letter-spacing: 0.15px;
                }
                .p-back-button {
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    color: #107970;
                    background: none;
                    border: none;
                    cursor: pointer;
                    font-size: inherit;
                    font-weight: inherit;
                    padding: 0;
                }
                .p-back-icon {
                    width: 16px;
                    height: 20px;
                    object-fit: contain;
                }
                .category-card {
                    width: 100%;
                    height: 30px;
                    justify-content: flex-start;
                    display: flex;
                    margin-top: 10px;
                    padding-right: 34px;
                    gap: 8px;
                    font-size: 16px;
                    color: rgba(0, 0, 0, 0.38);
                    white-space: nowrap;
                    overflow: scroll;
                }
                .counter {
                    width: 100%;
                    display: flex;
                    margin-top: 10px;
                    flex-direction: row;
                    flex-wrap: wrap;
                    justify-content: flex-start;
                }
                .counter-input{
                    margin-right: 5px;
                    border-radius: 10px;
                    width: 60%;
                    font-size: large;
                    color: #107970;
                    border-color: lightgray;
                    border: 1px solid #ccc !important;
                    display: flex;
                    justify-content: center;
                    align-content: center;
                    flex-direction: row;
                    align-items: center;
                }
                .counter-input[type=number] {
                    padding: 7px 10px;
                }
                .counter-add{
                    border-radius: 10px 0px 0px 10px;
                    font-size: x-large;   
                    border-color: lightgray;
                    border: 1px solid #ccc;
                    border-right: 1px;
                    width: 19%;
                    display: flex;
                    justify-content: center;
                    align-content: center;
                    flex-direction: row;
                    align-items: flex-start;
                }
                .counter-minus{
                    border-radius: 0px 10px 10px 0px;
                    font-size: x-large;
                    border-color: lightgray;
                    border: 1px solid #ccc !important;
                    width: 19%;
                    display: flex;
                    justify-content: center;
                    align-content: center;
                    flex-direction: row;
                    align-items: flex-start;
                }
                .purchase{
                    display: flex;
                    margin-top: 20px;
                    margin-bottom: 10px;
                    width: 100%;
                    height: 35px;
                    font-feature-settings: "clig" off, "liga" off;
                    font-family: YuPearl, sans-serif;
                    border-radius: 100px;
                    border: 1px solid;
                    justify-content: center;
                    padding: 3px 4px;
                    border-color: #107970;
                    background-color: #107970;
                    color: #fff;
                    align-items: center;
                }
            `}
            </style>
        </div>
    );
};

export default PurchaseCard;
