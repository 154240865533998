import React, { useState, useEffect, Component  } from "react";
import { useParams, useNavigate, useLocation  } from 'react-router-dom';
import { generateClient } from 'aws-amplify/api';
import { getEvent } from '../../graphql/queries';

const ListItem = ({ key, date, title, timeSection, place, points, type, para, eventID, userID, savedList }) => {
    let navigate = useNavigate();

    const client = generateClient();
    const [event, setEvent] = useState();
    
    const today = new Date();
    var tmonthVal = today.getMonth() + 1;
    var dayVal = today.getDate();
    if(tmonthVal < 10){
        tmonthVal = "0" + tmonthVal;
    }
    if(dayVal < 10){
        dayVal = "0" + dayVal;
    }
    const datetime = today.getFullYear() + "-" + tmonthVal + "-" + dayVal;

    const startDateFormat = new Date(date);
    const monthIndex = startDateFormat.getMonth();
    const dateVal = startDateFormat.getDate();
    const monthNames = ["一月", "二月", "三月", "四月", "五月", "六月", "七月", "八月", "九月", "十月", "十一月", "十二月"];
    const monthVal = monthNames[monthIndex];
    const id = eventID;

    const [itemClass, setItemClass] = useState('list-item');

    if (points > 0)
        points = "+" + points;

    const handleClick = () => {
        if (para !== "history"){
            navigate(`/activity/${id}`,{state: {id, userID, savedList}})
        }
    }

    async function fetchEvent() {
        try {
            const eventData = await client.graphql({
                    query: getEvent,
                    variables: { id: eventID }
            });
            const event = eventData.data.getEvent;
            setEvent(event);
        } catch (err) {
            console.log('error fetching events');
        }
    }

    useEffect(() => {
        if (type !== "event"){
            fetchEvent();
        }
    }, [])

    useEffect(() => {
        if (date < datetime){
            setItemClass(`list-item-disabled-${eventID}`);
        } else {
            setItemClass(`list-item`);
        }
    })

    if (type === "event"){
        if(date !== timeSection)
            timeSection = timeSection + " 至 " + date
        return (
            <div className={itemClass} onClick={handleClick}>
                <div className="item-main-info">
                    {/* <div className="item-date">{dateVal}</div> */}
                    <div className="item-main-info-1">{title}</div>
                    <div className="item-main-info-2">{points}</div>
                </div>
                <div className="item-secondary-info">
                    {/* <div className="item-month">{monthVal}</div> */}
                    <div>{timeSection}</div>
                    {/* <div>{place}</div> */}
                </div>
                <style jsx>
                {`
                    .list-item {
                        border-radius: 5px;
                        border: 1px solid rgba(130, 187, 182, 0.5);
                        background-color: #fff;
                        display: flex;
                        margin-top: 8px;
                        width: 100%;
                        flex-direction: column;
                        padding: 16px;
                    }
                    .list-item-disabled-${eventID} {
                        border-color: rgba(0, 0, 0, 0.15);
                        border-width: 2px;
                        background-color: rgba(0, 0, 0, 0.15);
                        color: rgba(0, 0, 0, 0.6);
                            border-radius: 5px;
                            border: 1px solid;
                            display: flex;
                            margin-top: 8px;
                            width: 100%;
                            flex-direction: column;
                            padding: 16px;
                    }
                    .list-item-selected {
                        border-radius: 5px;
                        border: 1px solid rgba(130, 187, 182, 0.5);
                        background-color: #82bbb6;
                        color: #fff;
                        display: flex;
                        margin-top: 8px;
                        width: 100%;
                        flex-direction: column;
                        padding: 16px;
                    }
                    .item-main-info {
                        justify-content: space-between;
                        display: flex;
                        gap: 20px;
                        font-size: 24px;
                        font-weight: 400;
                        letter-spacing: -1.5px;
                    }
                    .item-main-info-1{
                        text-wrap: balance;
                        text-align: start;
                    }
                    .item-main-info-2{
                        width: 40px;
                    }
                    .item-date {
                        text-align: center;
                        margin: auto 0;
                        width: 30px;
                    }
                    .item-secondary-info {
                        justify-content: space-between;
                        display: flex;
                        margin-top: 4px;
                        font-size: 14px;
                        font-weight: 500;
                        white-space: nowrap;
                        letter-spacing: 0.15px;
                        padding: 1px 0;
                    }
                    .item-secondary-info-1{
                        width: 100px;
                        text-wrap: balance;
                        text-align: end;
                    }
                    .item-secondary-info-2{
                        width: 100px;
                        text-wrap: balance;
                        text-align: end;
                    }
                    .item-month {
                        min-width: 60px;
                        text-wrap: balance;
                        text-align: start;
                    }
                `}
                </style>
            </div>
        );
    } else {
        if(event){
            if (event.points > 0)
                points = "+" + event.points;        
            return (
                <div className={itemClass} onClick={handleClick}>
                    <div className="item-main-info">
                        <div className="item-date">{dateVal}</div>
                        <div className="item-main-info-1">{title}</div>
                        <div className="item-main-info-2">{points}</div>
                    </div>
                    <div className="item-secondary-info">
                        <div className="item-month">{monthVal}</div>
                        <div className="item-secondary-info-1">{timeSection}</div>
                        <div className="item-secondary-info-2">{place}</div>
                    </div>
                    <style jsx>
                    {`
                        .list-item {
                            border-radius: 5px;
                            border: 1px solid rgba(130, 187, 182, 0.5);
                            background-color: #fff;
                            display: flex;
                            margin-top: 8px;
                            width: 100%;
                            flex-direction: column;
                            padding: 16px;
                        }
                        .list-item-disabled-${eventID} {
                            border-color: rgba(0, 0, 0, 0.15);
                            border-width: 2px;
                            background-color: rgba(0, 0, 0, 0.15);
                            color: rgba(0, 0, 0, 0.6);
                                border-radius: 5px;
                                border: 1px solid;
                                display: flex;
                                margin-top: 8px;
                                width: 100%;
                                flex-direction: column;
                                padding: 16px;
                        }
                        .list-item-selected {
                            border-radius: 5px;
                            border: 1px solid rgba(130, 187, 182, 0.5);
                            background-color: #82bbb6;
                            color: #fff;
                            display: flex;
                            margin-top: 8px;
                            width: 100%;
                            flex-direction: column;
                            padding: 16px;
                        }
                        .item-main-info {
                            justify-content: space-between;
                            display: flex;
                            gap: 20px;
                            font-size: 24px;
                            font-weight: 400;
                            letter-spacing: -1.5px;
                        }
                        .item-main-info-1{
                            text-wrap: balance;
                            text-align: center;
                        }
                        .item-main-info-2{
                            width: 40px;
                        }
                        .item-date {
                            text-align: center;
                            margin: auto 0;
                            width: 30px;
                        }
                        .item-secondary-info {
                            justify-content: space-between;
                            display: flex;
                            margin-top: 4px;
                            font-size: 14px;
                            font-weight: 500;
                            white-space: nowrap;
                            letter-spacing: 0.15px;
                            padding: 1px 0;
                        }
                        .item-secondary-info-1{
                            width: 100px;
                            text-wrap: balance;
                            text-align: end;
                        }
                        .item-secondary-info-2{
                            width: 100px;
                            text-wrap: balance;
                            text-align: end;
                        }
                        .item-month {
                            min-width: 60px;
                            text-wrap: balance;
                            text-align: start;
                        }
                    `}
                    </style>
                </div>
            );
        }
    }
};

export default ListItem;