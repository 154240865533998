import React, { useState } from 'react';
import { useParams, useNavigate, useLocation, Link  } from 'react-router-dom';
import UserPool from '../../UserPool';
import { generateClient } from 'aws-amplify/api';
import { createUser } from '../../graphql/mutations'

const Signup = () => {

    let navigate = useNavigate();
    const client = generateClient();

    const [dateType, setDateType] = useState("text"); 
    const [name, setName] = useState("");
    const [userName, setUserName] = useState("");
    const [gender, setGender] = useState("");
    const [birthday, setBirthday] = useState("");
    const [address, setAddress] = useState("");
    const [email, setEmail] = useState("");
    const [phoneNo, setPhoneNo] = useState("");
    const [relationship, setRelationship] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [warning, setWarning] = useState();
    
    const handleOnFocus = () => { 
        setDateType("date"); 
    }; 

    const handleBlur = () => { 
        setDateType("text"); 
    }; 

    const onBack = () => {
        navigate(-1)
    };

    async function newUser(userID) {
        try {
            const userData = await client.graphql({
                query: createUser,
                variables: {
                    input: {
                        id: userID,
                        savedEvent: [],
                        joinedEvent: [],
                        eventHistory: [],
                        savedProduct: [],
                        exchangeHistory: [],
                        followingOrganization: [],
                        totalTuenYuen: 0,
                        relationSanHui: relationship
                    }
                }
            });
        } catch (err) {
          console.log(err);
        }
    }

    const onSubmit = async (event) => {
        event.preventDefault();

        if (name === "") {
            setWarning(<p style={{color: 'red'}}>請輸入你的姓名!</p>)
        } else if (userName === "") {
            setWarning(<p style={{color: 'red'}}>請輸入你的用戶名稱!</p>)
        } else if (gender === "") {
            setWarning(<p style={{color: 'red'}}>請輸入你的性別!</p>)
        } else if (birthday === "") {
            setWarning(<p style={{color: 'red'}}>請輸入你的生日日期!</p>)
        } else if (address === "") {
            setWarning(<p style={{color: 'red'}}>請輸入你的地址!</p>)
        } else if (email === "") {
            setWarning(<p style={{color: 'red'}}>請輸入你的電郵地址!</p>)
        } else if (phoneNo === "") {
            setWarning(<p style={{color: 'red'}}>請輸入你的電話號碼!</p>)
        } else if (relationship === "") {
            setWarning(<p style={{color: 'red'}}>請輸入你與新墟的關係!</p>)
        } else if (password === "") {
            setWarning(<p style={{color: 'red'}}>請輸入你的密碼!</p>)
        } else if (confirmPassword === "") {
            setWarning(<p style={{color: 'red'}}>請輸入確認密碼!</p>)
        } else {
            if (phoneNo.length !== 8){
                setWarning(<p style={{color: 'red'}}>請輸入正確電話號碼!</p>)
            } else if (phoneNo.match(/^[0-9]+$/) === null) {
                setWarning(<p style={{color: 'red'}}>請輸入正確電話號碼!</p>)
            } else if (password.length < 8) {
                setWarning(<p style={{color: 'red'}}>密碼長度至少需達8個字元!</p>)
            } else if (!Boolean(password.match(/[A-Z]/))){
                setWarning(<p style={{color: 'red'}}>密碼必須包含大寫字母!</p>)
            } else if (!Boolean(password.match(/[a-z]/))){
                setWarning(<p style={{color: 'red'}}>密碼必須包含小寫字母!</p>)
            } else if (!Boolean(password.match(/[0-1]/))){
                setWarning(<p style={{color: 'red'}}>密碼必須包含數字!</p>)
            } else if (password !== confirmPassword){
                setWarning(<p style={{color: 'red'}}>密碼與確認密碼不符!</p>)
            } else {
                UserPool.signUp(
                    email, password, 
                    [
                        { Name: "name", Value: name },
                        { Name: "preferred_username", Value: userName },
                        { Name: "gender", Value: gender },
                        { Name: "birthdate", Value: birthday },
                        { Name: "phone_number", Value: "+852" + phoneNo },
                        { Name: "address", Value: address },
                    ],
                    null,
                    async (err, data) => {
                        if (err){
                            console.error(err) 
                        } else {
                            newUser(data.userSub);
                            navigate(`/confirm_code/${data.user.username}`)
                        }
                    }
                )
            }
        }
    }

    return (
        <div className="signup">
            <header className="header">
                <button className="back-button" onClick={onBack}>
                    <img src="\assets\icon\back.svg" alt="" className="back-icon" />
                    <span>返回</span>
                </button>
            </header>
            <div className="register-container">
                <h1 className="register-title">註冊個人帳戶</h1>
                <form onSubmit={onSubmit}>
                    <input
                        id="name"
                        className="input-field"
                        type="text"
                        placeholder="姓名"
                        onChange={(event) => setName(event.target.value)}
                    />
                    <input
                        id="username"
                        className="input-field"
                        type="text"
                        placeholder="暱稱"onChange={(event) => setUserName(event.target.value)}
                    />
                    <select
                        className="input-field"
                        id="gender"
                        onChange={(event) => setGender(event.target.value)}>
                            <option value="" disabled selected style={{color: "gray"}}>性別</option>
                            <option value="male">M</option>
                            <option value="female">F</option>
                    </select>
                    <input
                        id="birthday"
                        className="input-field"
                        type={dateType}
                        onFocus={handleOnFocus} 
                        onBlur={handleBlur} 
                        placeholder="出生年月"
                        onChange={(event) => setBirthday(event.target.value)}
                    />
                    <select
                        className="input-field"
                        id="address"
                        onChange={(event) => setAddress(event.target.value)}>
                            <option value="" disabled selected style={{color: "gray"}}>居住區域</option>
                            <option value="屯門">屯門</option>
                            <option value="元朗">元朗</option>
                            <option value="北區">北區</option>
                            <option value="荃灣">荃灣</option>
                            <option value="葵青">葵青</option>
                            <option value="大埔">大埔</option>
                            <option value="沙田">沙田</option>
                            <option value="深水埗">深水埗</option>
                            <option value="油尖旺">油尖旺</option>
                            <option value="九龍城">九龍城</option>
                            <option value="黃大仙">黃大仙</option>
                            <option value="觀塘">觀塘</option>
                            <option value="中西區">中西區</option>
                            <option value="灣仔">灣仔</option>
                            <option value="東區">東區</option>
                            <option value="南區">南區</option>
                            <option value="西貢">西貢</option>
                            <option value="離島">離島</option>
                    </select>
                    <input
                        id="email"
                        className="input-field"
                        type="email"
                        placeholder="電郵地址"
                        onChange={(event) => setEmail(event.target.value)}
                    />
                    <input
                        id="phoneNo"
                        className="input-field"
                        type="tel"
                        placeholder="電話號碼"
                        onChange={(event) => setPhoneNo(event.target.value)}
                    />
                    <select
                        className="input-field"
                        id="relationship"
                        onChange={(event) => setRelationship(event.target.value)}>
                            <option value="" disabled selected style={{color: "gray"}}>你與新墟的關係</option>
                            <option value="在新墟區居住">在新墟區居住</option>
                            <option value="在新墟區上學或上班">在新墟區上學或上班</option>
                            <option value="新墟區是我生活的圈子">新墟區是我生活的圈子</option>
                            <option value="對新墟區/新墟議題感興趣">對新墟區/新墟議題感興趣</option>
                            <option value="以上皆非">以上皆非</option>
                    </select>
                    <input
                        id="password"
                        className="input-field"
                        type="password"
                        placeholder="密碼"
                        onChange={(event) => setPassword(event.target.value)}
                    />
                    <input
                        id="confirmPassword"
                        className="input-field"
                        type="password"
                        placeholder="再次輸入密碼"
                        onChange={(event) => setConfirmPassword(event.target.value)}
                    />
                    {warning}      
                    <button type="submit" className="register-button">註冊</button>
                </form>
            </div>
            
            <style jsx>
            {`
                input {
                    line-height: 2em; // 2em is (2 * default line height)
                }
                select {
                    height: 3.2em; // 2em is (2 * default line height)
                }
                .signup{
                    width: 100%;
                    min-height: 100vh;
                    background-color: var(--Background-paper-elevation-0, #f2f2f2);
                }
                .header {
                    top: 0;
                    position: sticky;
                    z-index: 99;
                    display: flex;
                    justify-content: space-between;
                    align-self: flex-start;
                    padding: 15.5px 16px 12px 8px;
                    font-size: 20px;
                    font-weight: 500;
                    letter-spacing: 0.15px;
                    background-color: var(--Background-paper-elevation-0, #f2f2f2);
                }
                .back-button {
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    color: #107970;
                    background: none;
                    border: none;
                    cursor: pointer;
                    font-size: inherit;
                    font-weight: inherit;
                }
                .back-icon {
                    width: 16px;
                    height: 20px;
                    object-fit: contain;
                }
                .register-container {
                    display: flex;
                    flex-direction: column;
                    padding: 19px 28px 20px;
                    font-size: 20px;
                    color: var(--text-secondary);
                    font-weight: 500;
                    letter-spacing: 0.15px;
                }
                .register-title {
                    color: var(--text-primary);
                    text-align: center;
                    font-feature-settings: "clig" off, "liga" off;
                    letter-spacing: -1.5px;
                    font: 400 28px YuPearl, sans-serif;
                    margin-top: 0px;
                    margin-bottom: 0px;
                    align-self: self-start;
                }
                .input-field {
                    font-feature-settings: "clig" off, "liga" off;
                    border-radius: 8px;
                    background-color: #fff;
                    margin-top: 28px;
                    padding: 8px 16px;
                    border: 1px solid rgba(0, 0, 0, 0.6);
                    width: 100%;
                    box-sizing: border-box;
                }
                .dropdown-container {
                    border-radius: 8px;
                    background-color: #fff;
                    display: flex;
                    margin-top: 28px;
                    width: 100%;
                    flex-direction: column;
                    justify-content: center;
                    padding: 0 12px;
                    border: 1px solid rgba(0, 0, 0, 0.6);
                    box-sizing: border-box;
                }
                .dropdown-content {
                    display: flex;
                    padding: 16px 0;
                }
                .dropdown-label {
                    font-feature-settings: "clig" off, "liga" off;
                    flex: 1;
                    margin: auto 0;
                }
                .dropdown-icon {
                    aspect-ratio: 1;
                    object-fit: auto;
                    object-position: center;
                    width: 24px;
                }
                .register-button {
                    font-feature-settings: "clig" off, "liga" off;
                    border-radius: 4px;
                    background-color: #107970;
                    margin-top: 32px;
                    color: #fff;
                    padding: 8px 22px;
                    border: none;
                    cursor: pointer;
                    font-size: 20px;
                    font-weight: 500;
                }
            `}
            </style>
        </div>
    )
};

export default Signup;