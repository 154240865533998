import EventCard from './event_card/event_card';
import ActivityDetail from './activityDetail';
import SlotList from './slot_picker';

import { useContext, useEffect, useState } from 'react'
import { useParams, useNavigate, useLocation, Link  } from 'react-router-dom';
import { AccountContext } from '../../Account';

import { generateClient } from 'aws-amplify/api';
import { getEvent } from '../../graphql/queries';

const Activity = () => {
    const activityID = useLocation().state.id;
    const userID = useLocation().state.userID;
    const savedList = useLocation().state.savedList;
    let navigate = useNavigate();

    const [state, setState] = useState(false);
    const { getSession, logout } = useContext(AccountContext);

    const client = generateClient();
    const [event, setEvent] = useState();

    async function fetchEvent() {
        try {
            const eventData = await client.graphql({
                    query: getEvent,
                    variables: { id: activityID }
            });
            const event = eventData.data.getEvent;
            setEvent(event);
        } catch (err) {
            console.log('error fetching events');
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        getSession().then((session) => {
            setState(true);
            fetchEvent();
        })
        .catch((err) => {
            navigate('/login');
        })
    }, [])
    
    const onBack = () => {
        navigate(-1)
    };
    
    const [pickerClass, setPickerClass] = useState("activity-item active");
    const [detailClass, setDetailClass] = useState("activity-item inactive");
    const [container, setContainer] = useState(<SlotList userID={userID} />);

    const pickerOnClick = () => {
        setContainer(<SlotList userID={userID}/>);
        setPickerClass("activity-item active");
        setDetailClass("activity-item inactive");
    }

    const detailOnClick = () => {
        setContainer(<ActivityDetail state={event}/>)
        setDetailClass("activity-item active");
        setPickerClass("activity-item inactive");
    }
    
    if (event) {
        let isNegative = false;
        var pointsVal = ""
        if (event.points > 0)
            pointsVal = "+" + event.points + "屯圓"
        else {
            pointsVal = event.points + "屯圓"
            isNegative = true;
        }
        return (
            <div className="activity">
                <header className="header">
                    <button className="back-button" onClick={onBack}>
                        <img src="\assets\icon\back.svg" alt="" className="back-icon" />
                        <span>返回</span>
                    </button>
                    {/* <Link to={`/activity/slotPicker/${activityID}`} state={event}>
                        <button className="join-button">參加</button>
                    </Link> */}
                </header>
                <div className="image-section">
                    <div className={`pricing-container ${isNegative ? "pointsTextNegative" : ""}`}>
                        <p className="pricing-text">
                            {pointsVal}<span className="pricing-unit">/時段</span>
                        </p>
                    </div>
                </div>
                <EventCard state={event} userID={userID} savedList={savedList}/>
                <div className="activity-section">
                    <div className={detailClass} onClick={pickerOnClick}>
                        活動時段
                    </div>
                    |
                    <div className={pickerClass} onClick={detailOnClick}>
                        活動詳情
                    </div>
                </div>
                {container}
                <style jsx>
                {`
                    .activity{
                        width: 100%;
                        min-height: 100vh;
                        background-color: var(--Background-paper-elevation-0, #f2f2f2);
                    }
                    .header {
                        top: 0;
                        position: sticky;
                        z-index: 99;
                        background-color: var(--Background-paper-elevation-0, #f2f2f2);
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding: 12px 16px 12px 8px;
                        font-size: 20px;
                        font-weight: 500;
                        letter-spacing: 0.15px;
                    }
                    .back-button {
                        display: flex;
                        align-items: center;
                        gap: 8px;
                        color: #107970;
                        background: none;
                        border: none;
                        cursor: pointer;
                        font-size: inherit;
                        font-weight: inherit;
                    }
                    .back-icon {
                        width: 16px;
                        height: 20px;
                        object-fit: contain;
                    }
                    .join-button {
                        border-radius: 100px;
                        border: 1px solid #82bbb6;
                        color: #82bbb6;
                        background: none;
                        padding: 4px 12px;
                        font-size: inherit;
                        font-weight: inherit;
                        cursor: pointer;
                    }
                    .image-section {
                        border-radius: 10px;
                        border: 1px solid rgba(0, 0, 0, 0.12);
                        background-color: rgba(0, 0, 0, 0.12);
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        padding: 24px 0 80px;
                        margin: 0px 20px 20px 20px;
                        height: 230px;
                        max-width: 400px;
                        background-image: url(/assets/Events/${event.imageUrl});
                        background-size: cover;
                        background-repeat: no-repeat;
                        background-position-y: center;
                        border: 0;
                    }
                    .pricing-container {
                        font-family: YuPearl, sans-serif;
                        border-radius: 0 5px 5px 0;
                        background-color: #107970;
                        padding: 8px 8px 8px 20px;
                        left: 0;
                        position: absolute;
                    }
                    .pointsTextNegative {
                        background-color: #c35540;
                    }
                    .pricing-text {
                        font-size: 20px;
                        color: #fff;
                        font-weight: 500;
                        letter-spacing: 0.15px;
                        margin: 0;
                    }
                    .pricing-unit {
                        font-size: 16px;
                        line-height: 16px;
                    }
                    .activity-section {
                        display: flex;
                        justify-content: center;
                        width: 100%;
                        gap: 20px;
                        padding: 8px 48px;
                        font-size: 20px;
                        font-weight: 500;
                        letter-spacing: 0.15px;
                        font-feature-settings: "clig" off, "liga" off;
                        font-family: YuPearl, sans-serif;
                        max-width: 400px;
                    }
                    .activity-item {
                        white-space: nowrap;
                    }
                    .active {
                        color: var(--text-tertiary, rgba(0, 0, 0, 0.38));
                    }
                    .inactive {
                        color: var(--text-secondary, rgba(0, 0, 0, 0.6));
                    }
                `}
                </style>
            </div>
        )
    } else {
        return (
            <div>Loading...</div>
        );
    }
};

export default Activity;
