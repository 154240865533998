import { useContext, useEffect, useState } from 'react'
import { useParams, useNavigate, useLocation, Link  } from 'react-router-dom';
import { AccountContext } from '../Account';
import EventList from '../components/my_activities/event_list';

const MyActivity = ({path}) => {

    let navigate = useNavigate();

    const [state, setState] = useState(false);
    const [userID, setUserID] = useState("");
    const { getSession, logout } = useContext(AccountContext);

    useEffect(() => {
        window.scrollTo(0, 0);
        getSession().then((session) => {
            setState(true);
            setUserID(session.accessToken.payload.username);
        })
        .catch((err) => {
            navigate('/login');
        })
    }, [])

    var list = <EventList para="current" event={false} mine={true}/>;

    if (path === "activities"){
        list = <EventList id={userID} para="current" event={false} mine={true}/>;
    } else if (path === "history"){
        list = <EventList id={userID} para="history" event={false} mine={true}/>;
    }

    return (
        <div className="my_activity">
            <div className="date-time-location-list">
                <div className="list-container">
                    <div className="sub-header">
                        <div className="sub-header-item">日期</div>
                        <div className="sub-header-item">活動及時間</div>
                        <div className="sub-header-item sub-header-item-center">屯圓</div>
                    </div>
                </div>
                {list}
            </div>
            <style jsx>
            {`
                .my_activity{
                    width: 100%;
                    min-height: 100vh;
                    background-color: var(--Background-paper-elevation-0, #f2f2f2);
                }
                .date-time-location-list {
                    align-self: stretch;
                    display: flex;
                    max-width: 393px;
                    flex-direction: column;
                    color: #107970;
                    justify-content: center;
                    padding-bottom: 30px;
                }
                .list-container {
                    background-color: var(--background-paper-elevation-0);
                    display: flex;
                    width: 100%;
                    flex-direction: column;
                    padding: 8px 20px 0;
                }
                .sub-header {
                    justify-content: space-between;
                    border-radius: 5px;
                    background-color: #82bbb6;
                    display: flex;
                    gap: 10px;
                    font-size: 20px;
                    color: #fff;
                    font-weight: 500;
                    white-space: nowrap;
                    letter-spacing: 0.15px;
                    padding: 10px 16px;
                }
                .sub-header-item {
                    font-feature-settings: "clig" off, "liga" off;
                }
                .sub-header-item-center {
                    text-align: center;
                }
            `}
            </style>
        </div>
    )
};

export default MyActivity;
