import React, { useContext, useEffect, useState } from 'react'
import { useParams, useNavigate, useLocation  } from 'react-router-dom';
import { AccountContext } from '../../Account';
import { generateClient } from 'aws-amplify/api';
import { getEvent, getEventDateTime } from '../../graphql/queries';

const CompletionDialog  = (slotID) => {
    let navigate = useNavigate();
    const client = generateClient();
    const [slot, setSlot] = useState();
    const [state, setState] = useState(false);
    const { getSession, logout } = useContext(AccountContext);
    
    const monthNames = ["一月", "二月", "三月", "四月", "五月", "六月", "七月", "八月", "九月", "十月", "十一月", "十二月"];

    const activityID = useLocation().state.activityID;

    async function fetchSlot() {
        try {
            const slotData = await client.graphql({
                    query: getEventDateTime,
                    variables: { id: activityID }
            });
            const slot = slotData.data.getEventDateTime;
            setSlot(slot);
        } catch (err) {
            console.log('error fetching slot');
        }
    }
    
    const onBack = () => {
        navigate(-2)
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        getSession().then((session) => {
            setState(true);
            fetchSlot();
        })
        .catch((err) => {
            navigate('/login');
        })
    }, []);

    if(slot){
        var startDateFormat = new Date(slot.date);
        var startMonth = startDateFormat.getMonth();
        var startDateVal = startDateFormat.getDate();
        return (
            <div className="completion-dialog">
                <div className="dialog-content">
                    <div className="dialog-header">
                        <h1 className="dialog-title">完成 !</h1>
                    </div>
                    <div className="dialog-body">
                        <p className="success-message">已成功參加時段:</p>
                        <div className="list-item">
                            <div className="item-main-info">
                                <div className="item-date">{startDateVal}</div>
                                <div style={{display: "table-cell", width: 170, textAlign: "center"}}>{slot.startTime} - {slot.endTime}</div>
                                <div style={{display: "table-cell", width: 60, textAlign: "center"}}>{slot.currentParticipants}/{slot.maxParticipants}</div>
                            </div>
                            <div className="item-secondary-info">
                                <div className="item-month">{monthNames[startMonth]}</div>
                                <div style={{width: 5}}></div>
                                <div style={{display: "table-cell", marginLeft: 75}}>{slot.place}</div>
                            </div>
                        </div>
                        <div style={{margin: 20, display: "inline-flex", alignItems: "flex-start", gap: 10}}>
                            <div className="icon-wrapper">
                                <img src="\assets\icon\alert.svg" alt="" className="info-icon" />
                            </div>
                            <p className="info-text">
                                留意活動開始前24小時不能取消參加活動，如沒有出席，相應的活動獎勵或會從你的戶口扣除。
                            </p>
                        </div>
                    </div>
                </div>
                <button className="finish-button" onClick={onBack}>完成</button>
                <style jsx>
                {`
                    .completion-dialog {
                        width: 100%;
                        min-height: 100vh;
                        background-color: var(--Background-paper-elevation-0, #f2f2f2);
                    }
                    .dialog-background {
                        background-color: rgba(242, 242, 242, 1);
                        position: absolute;
                        z-index: 0;
                        display: flex;
                        min-height: 1266px;
                        width: 393px;
                        right: 0;
                        bottom: -554px;
                        height: 1266px;
                    }
                    .dialog-content {
                        position: absolute;
                        z-index: 1;
                        display: flex;
                        width: 353px;
                        max-width: 100%;
                        flex-direction: column;
                        align-items: center;
                        justify-content: start;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                    }
                    .dialog-header {
                        position: relative;
                        display: flex;
                        width: 100%;
                        max-width: 353px;
                        flex-direction: column;
                        padding: 0 24px;
                    }
                    .dialog-title {
                        color: var(--Primary-dark, #107970);
                        text-align: center;
                        letter-spacing: -1.5px;
                        font: 400 24px YuPearl, sans-serif;
                        margin: 0;
                    }
                    .dialog-body {
                        display: flex;
                        margin-top: 12px;
                        width: 100%;
                        max-width: 353px;
                        padding-bottom: 20px;
                        flex-direction: column;
                        font-family: YuPearl, sans-serif;
                        color: var(--Primary-dark, #107970);
                    }
                    .success-message {
                        border-radius: 4px;
                        width: 100%;
                        font-size: 20px;
                        color: var(--text-secondary, rgba(0, 0, 0, 0.6));
                        font-weight: 500;
                        white-space: nowrap;
                        text-align: center;
                        letter-spacing: 0.15px;
                        padding: 4px 0;
                        margin: 0 0 8px 0;
                    }
                    .list-item {
                        border-radius: 5px;
                        border: 1px solid rgba(130, 187, 182, 0.5);
                        background-color: #fff;
                        display: flex;
                        margin-top: 20px;
                        margin-left: 20px;
                        margin-right: 20px;
                        width: 90%;
                        flex-direction: column;
                        padding: 16px;
                        color: #107970;
                    }
                    .list-item-disabled {
                        margin-top: 20px;
                        margin-left: 20px;
                        margin-right: 20px;
                        width: 90%;
                        border-radius: 5px;
                        border: 1px solid rgba(130, 187, 182, 0.5);
                        background-color: rgba(0, 0, 0, 0.15);
                        color: rgba(0, 0, 0, 0.6);
                        padding: 16px;
                    }
                    .item-main-info {
                        justify-content: space-evenly;
                        display: table;
                        gap: 20px;
                        font-size: 24px;
                        font-weight: 400;
                        letter-spacing: -1.5px;
                        flex-direction: row;
                    }
                    .item-date {
                        display: table-cell;
                        text-align: center;
                        width: 50px;
                        margin: auto 0;
                    }
                    .item-secondary-info {
                        justify-content: space-evenly;
                        display: table;
                        margin-top: 4px;
                        gap: 55px;
                        font-size: 14px;
                        font-weight: 500;
                        white-space: nowrap;
                        letter-spacing: 0.15px;
                        padding: 1px 0;
                    }
                    .item-month {
                        display: table-cell;
                        text-align: center;
                        width: 55px;
                    }
                    .info-message {
                        display: flex;
                        margin-top: 12px;
                        width: 100%;
                        align-items: start;
                        gap: 16px;
                        justify-content: center;
                    }
                    .icon-wrapper {
                        display: flex;
                        min-height: 22px;
                        justify-content: start;
                        width: 22px;
                    }
                    .info-icon {
                        aspect-ratio: 1;
                        object-fit: contain;
                        object-position: center;
                        width: 22px;
                        flex: 1;
                    }
                    .info-text {
                        color: var(--text-secondary, rgba(0, 0, 0, 0.6));
                        letter-spacing: 0.15px;
                        flex: 1;
                        font: 500 16px YuPearl, sans-serif;
                        margin: 0;
                    }
                    .finish-button {
                        font-family: YuPearl, sans-serif;
                        justify-content: center;
                        align-items: center;
                        border-radius: 100px;
                        border: 1px solid rgba(130, 187, 182, 1);
                        width: 100%;
                        color: var(--Primary-main, #82bbb6);
                        white-space: nowrap;
                        text-align: center;
                        padding: 4px;
                        background: none;
                        cursor: pointer;
                        font-size: inherit;
                        position: fixed;
                        bottom: 0;
                        width: 90%;
                        margin: 20px;
                    }
                `}
                </style>
            </div>
        );
    } else {
        return (
            <div>Loading...</div>
        );
    }
};

export default CompletionDialog ;