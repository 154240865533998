import { useContext, useEffect, useState } from 'react'
import { useParams, useNavigate, useLocation, Link  } from 'react-router-dom';
import { AccountContext } from '../../Account';

import { generateClient } from 'aws-amplify/api';
import { getOrganization, eventsByOrganizationID } from '../../graphql/queries';
import { updateUser, updateOrganization } from '../../graphql/mutations'

import EventList from '../my_activities/event_list';

const OrganizationProfile = () => {
    
    const client = generateClient();

    let navigate = useNavigate();
    const organizationID = useLocation().state.id;
    const userID = useLocation().state.userID;
    const [followedList, setFollowedList] = useState(useLocation().state.followedList);

    const [state, setState] = useState(false);
    const { getSession, logout } = useContext(AccountContext);
    const [eventClass, setEventClass] = useState("activity-item inactive");
    const [historyClass, setHistoryClass] = useState("activity-item active");
    const [organization, setOrganization] = useState();
    const [events, setEvents] = useState();
    const [followText, setFollowText] = useState("追隨");
    const [followClass, setFollowClass] = useState("");
    const [follower, setFollower] = useState("");

    const activities = [
      { title: '寵物護理員', count: 30 },
      { title: '繪畫工作坊', count: 30 },
    ];
    
    const onBack = () => {
        navigate(-1)
    };

    
    const [container, setContainer] = useState(<EventList id={organizationID} para="current" event={true} mine={false}/>);
        
    const eventOnClick = () => {
        setContainer(<EventList id={organizationID} para="current" event={true} mine={false}/>);
        setEventClass("activity-item inactive");
        setHistoryClass("activity-item active");
    }

    const historyOnClick = () => {
        setContainer(<EventList id={organizationID} para="history" event={true} mine={false}/>)
        setEventClass("activity-item active");
        setHistoryClass("activity-item inactive");
    }

    async function fetchOrganization() {
        try {
            const organizationData = await client.graphql({
                    query: getOrganization,
                    variables: { id: organizationID }
            });
            const organization = organizationData.data.getOrganization;
            setOrganization(organization);
            setFollower(organization.following);
        } catch (err) {
            console.log('error fetching organization');
        }
    }

    async function fetchEvents() {
        try {
          const eventsData = await client.graphql({
                query: eventsByOrganizationID,
                variables: { organizationID: organizationID },
          });
          const events = eventsData.data.eventsByOrganizationID.items;
          setEvents(events);
        } catch (err) {
          console.log('error fetching todos');
        }
    }

    async function updateFollowedList(newFollowedList) {
        try {
            const result = await client.graphql({
                query: updateUser,
                variables: {
                  input: {
                    id: userID,
                    followingOrganization: newFollowedList
                  }
                }
            });
        } catch (err) {
          console.log(err);
        }
    }

    async function updateFollower(followerNo) {
        try {
            const result = await client.graphql({
                query: updateOrganization,
                variables: {
                  input: {
                    id: organizationID,
                    following: followerNo
                  }
                }
            });
        } catch (err) {
          console.log(err);
        }
    }

    const handleFollow = (evt) => { 
        evt.stopPropagation();
        evt.cancelBubble = true;
        if (followedList.includes(organizationID)){
            setFollowText("追隨");
            setFollowClass(`follow-button-` + organizationID);
            var newList = followedList.filter((item) => item !== organizationID);
            setFollowedList(newList);
            updateFollowedList(newList);
            updateFollower(organization.following-1)
            setFollower(follower-1);
        } else {
            setFollowText("已追隨");
            setFollowClass(`followed-button-` + organizationID);
            var newList = [...followedList, organizationID];
            setFollowedList(newList);
            updateFollowedList(newList);
            updateFollower(organization.following+1)
            setFollower(follower+1);
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        getSession().then((session) => {
            setState(true);
            fetchOrganization();
            fetchEvents();
            if (followedList.includes(organizationID)){
                setFollowText("已追隨");
                setFollowClass(`followed-button-` + organizationID);
            } else {
                setFollowText("追隨");
                setFollowClass(`follow-button-` + organizationID);
            }
        })
        .catch((err) => {
            navigate('/login');
        })
    }, []);
        
    if(organization && events){

        return (
            <div className="profile-container">
                <header className="header">
                    <button className="back-button" onClick={onBack}>
                        <img src="\assets\icon\back.svg" alt="" className="back-icon" />
                        <span>返回</span>
                    </button>
                    <button className={followClass} onClick={handleFollow}>
                    {followText}
                    </button>
                </header>
                <div className="background">
    
                </div>
                <div className="profile-header">
                    <div className="profile-image">
                        <div className="image-placeholder" />
                    </div>
                    <div className="profile-stats">
                        <div className="stat-item">
                            <div className="stat-label">活動</div>
                            <div className="stat-value">{organization.currentEvents}</div>
                        </div>
                        <div className="divider-vertical">
                            <div className="divider" />
                        </div>
                        <div className="stat-item">
                            <div className="stat-label">追隨者</div>
                            <div className="stat-value">{follower}</div>
                        </div>
                    </div>
                </div>
                <div className="profile-info">
                    <h1 className="organization-name">{organization.name}</h1>
                    <p className="organization-description">
                        {organization.description}
                    </p>
                </div>
                <div className="activity-section">
                    <div className={eventClass} onClick={eventOnClick}>
                        活動
                    </div>
                    |
                    <div className={historyClass} onClick={historyOnClick}>
                        已舉辦活動
                    </div>
                </div>
                {container}
                <style jsx>
                {`
                    .profile-container {
                        width: 100%;
                        min-height: 100vh;
                        background-color: var(--Background-paper-elevation-0, #f2f2f2);
                    }
                    .header {
                        top: 0;
                        position: sticky;
                        z-index: 99;
                        background-color: var(--Background-paper-elevation-0, #f2f2f2);
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding: 12px 16px 12px 8px;
                        font-size: 20px;
                        font-weight: 500;
                        letter-spacing: 0.15px;
                        width: 100%;
                    }
                    .back-button {
                        display: flex;
                        align-items: center;
                        gap: 8px;
                        color: #107970;
                        background: none;
                        border: none;
                        cursor: pointer;
                        font-size: inherit;
                        font-weight: inherit;
                    }
                    .back-icon {
                        width: 16px;
                        height: 20px;
                        object-fit: contain;
                    }
                    .follow-button-${organizationID} {
                        border-radius: 100px;
                        border: 1px solid #82bbb6;
                        color: #82bbb6;
                        background: none;
                        padding: 4px 12px;
                        font-size: inherit;
                        font-weight: inherit;
                        cursor: pointer;
                    }
                    .followed-button-${organizationID} {
                        border-radius: 100px;
                        border: 1px solid #82bbb6;
                        color: var(--primary-contrast, #fff);
                        background-color: var(--Primary-dark, #107970);
                        padding: 4px 12px;
                        font-size: inherit;
                        font-weight: inherit;
                        cursor: pointer;
                    }
                    .background {
                        border-bottom: 1px solid darkgray;
                        background-color: lightgray;
                        position: static;
                        min-height: 130px;
                        background-image: url(/assets/Organization/background/${organization.background});
                        background-size: cover;
                        background-repeat: no-repeat;
                        background-position-y: center;
                    }
                    .header-space {
                        z-index: 0;
                        display: flex;
                        min-height: 120px;
                        width: 100%;
                    }
                    .profile-header {
                        z-index: 0;
                        display: flex;
                        margin-left: 20px;
                        margin-right: 20px;
                        align-items: flex-end;
                        margin-top: 20px;
                        align-items: center;
                    }
                    .profile-image {
                        z-index: 1;
                        border-radius: 100px;
                        border: 1.5px solid #107970;
                        display: flex;
                        height: 140px;
                        width: 140px;
                        overflow: hidden;
                        align-items: center;
                        justify-content: center;
                        margin-top: -100px;
                    }
                    .image-placeholder {
                        background-color: #d9d9d9;
                        min-height: 155px;
                        width: 147px;
                        background-image: url(/assets/Organization/logo/${organization.icon});
                        background-size: cover;
                        background-repeat: round;
                    }
                    .profile-stats {
                        display: flex;
                        padding-bottom: 10px;
                        align-items: flex-start;
                        justify-content: center;
                        flex: 1;
                        margin-top: -10px;
                    }
                    .stat-item {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        font-family: YuPearl, sans-serif;
                        white-space: nowrap;
                        flex: 1;
                    }
                    .stat-label {
                        color: rgba(0, 0, 0, 0.38);
                        font-size: 16px;
                        font-weight: 500;
                        letter-spacing: 0.15px;
                    }
                    .stat-value {
                        color: rgba(0, 0, 0, 0.6);
                        font-size: 28px;
                        font-weight: 400;
                        letter-spacing: -1.5px;
                        margin-top: 12px;
                    }
                    .divider-vertical {
                        align-self: stretch;
                        display: flex;
                        flex-direction: column;
                        width: 1px;
                        background-color: lightgray;
                    }
                    .divider {
                        min-height: 56px;
                        width: 0;
                        flex: 1;
                    }
                    .profile-info {
                        z-index: 0;
                        display: flex;
                        margin-left: 20px;
                        margin-right: 20px;
                        flex-direction: column;
                        font-family: YuPearl, sans-serif;
                        white-space: nowrap;
                        padding: 0 20px;
                    }
                    .organization-name {
                        font-size: 28px;
                        color: rgba(0, 0, 0, 0.6);
                        font-weight: 400;
                        letter-spacing: -1.5px;
                        min-height: 36px;
                        width: 100%;
                        margin-bottom: 0;
                    }
                    .organization-description {
                        color: var(--primary-main, #82bbb6);
                        font-family: YuPearl, sans-serif;
                        margin-top: 16px;
                        width: 100%;
                        white-space: break-spaces;
                        text-overflow: ellipsis;
                        margin-bottom: 24px;
                    }
                    .activity-section {
                        display: flex;
                        justify-content: center;
                        width: 100%;
                        gap: 20px;
                        padding: 8px 48px;
                        font-size: 20px;
                        font-weight: 500;
                        letter-spacing: 0.15px;
                        font-feature-settings: "clig" off, "liga" off;
                        font-family: YuPearl, sans-serif;
                        max-width: 400px;
                    }
                    .activity-item {
                        white-space: nowrap;
                    }
                    .active {
                        color: var(--text-tertiary, rgba(0, 0, 0, 0.38));
                    }
                    .inactive {
                        color: var(--text-secondary, rgba(0, 0, 0, 0.6));
                    }
                `}
                </style>
            </div>
        );
    }
};

export default OrganizationProfile;