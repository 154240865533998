'use client'

import logo from './logo.svg';
 
import { useContext, useEffect, useState } from 'react'
import { Account } from './Account';
import MainHeader from "./components/header/header_main";
import ActivitiesHeader from "./components/header/header_activities";
import MyActivitiesHeader from "./components/header/header_my_activities";
import ExchangeHeader from "./components/header/header_exchange_goods";
import MainFooter from "./components/footer/footer_main";
import Home from "./fragment/home";
import ActivitiesPage from "./fragment/activities";
import MyActivity from './fragment/my_activities'
import ExchangeGoods from './fragment/exchange_goods'
import Activity from "./components/activity/activity"
import OrganizationProfile from './components/institution/institution';
import MyQRCode from './components/page/my_qr_code';
import SignUp from './components/page/signup';
import Login from './components/page/login';
import ConfirmCode from './components/page/confirm_message';
import DuplicatedTimeslot from './components/handleEvent/failJoin';

import DateTimeLocationPicker from './components/activity/date_time_location_picker'

import ReactDOM from 'react-dom/client';
import { Routes, Route, HashRouter, Link } from 'react-router-dom';

import { Amplify } from 'aws-amplify';
import config from './aws-exports';
import { generateClient } from 'aws-amplify/api';
import SlotDetail from './components/activity/slot_detail';
import CompletionDialog from './components/handleEvent/successJoin';
import CancelDialog from './components/handleEvent/successCancel';
import FullTimeslot from './components/handleEvent/failJoin_full';
import CancelFail from './components/handleEvent/failCancel';
import Product from './components/products/product';
import PassTimeslot from './components/handleEvent/failJoin_pass';
import UpComing from './components/page/upcoming';
import ForgotPassword from './components/page/forgotPassword';
import ConfirmForgot from './components/page/confirmForgot';

function App() {
    Amplify.configure(config);

    return (
        <Account>
            <HashRouter>
                <Routes>
                    <Route index path="/" element={<MainHeader/>}/>
                    <Route path="/activities/:key" element={<ActivitiesHeader path="activities"r/>}/>
                    <Route path="/institutions/:key" element={<ActivitiesHeader path="institutions"/>}/>
                    <Route path="/my_activities" element={<MyActivitiesHeader path="activities"/>}/>
                    <Route path="/my_history" element={<MyActivitiesHeader path="history"/>}/>
                    {/* <Route path="/exchange_goods/:key" element={<ExchangeHeader path="goods"/>}/>
                    <Route path="/exchange_history/:key" element={<ExchangeHeader path="history"/>}/> */}
                    <Route path="/exchange_goods/:key" element={""}/>
                    <Route path="/exchange_history/:key" element={""}/>

                    <Route path="/activity/:key" element={""} />
                    <Route path="/institution/:key" element={""} />
                    <Route path="/activity/slotPicker/:key" element={""} />
                    <Route path="/activity/slotDetail/:key" element={""} />
                    <Route path="/product/:key" element={""} />

                    <Route path="/activity/slotDetail/:key/joinSuccess" element={""} />
                    <Route path="/activity/slotDetail/:key/cancelSuccess" element={""} />
                    <Route path="/activity/slotDetail/:key/cancelFailed" element={""} />
                    <Route path="/activity/slotDetail/:key/joinFailed" element={""} />
                    <Route path="/activity/slotDetail/:key/slotFull" element={""} />
                    <Route path="/activity/slotDetail/:key/slotPass" element={""} />
                        
                    <Route path="/signup" element={""} />
                    <Route path="/confirm_code/:key" element={""} />
                    <Route path="/login" element={""} />
                    <Route path="/forgotPassword" element={""} />
                    <Route path="/confirm_forgot/:key" element={""} />

                    <Route path="/profile/myQRcode" element={""}/>
                </Routes>
                <main>
                    <Routes>
                        <Route index path="/" element={<Home/>}/>
                        <Route path="/activities/:key" element={<ActivitiesPage path="activities"/>}/>
                        <Route path="/institutions/:key" element={<ActivitiesPage path="institutions"/>}/>
                        <Route path="/my_activities" element={<MyActivity path="activities"/>}/>
                        <Route path="/my_history" element={<MyActivity path="history"/>}/>
                        {/* <Route path="/exchange_goods/:key" element={<ExchangeGoods path="goods"/>}/>
                        <Route path="/exchange_history/:key" element={<ExchangeGoods path="history"/>}/> */}
                        <Route path="/exchange_goods/:key" element={<UpComing/>}/>
                        <Route path="/exchange_history/:key" element={<UpComing/>}/>
                        
                        <Route path="/activity/:key" element={<Activity/>} />
                        <Route path="/institution/:key" element={<OrganizationProfile/>} />
                        <Route path="/activity/slotPicker/:key" element={<DateTimeLocationPicker/>} />
                        <Route path="/activity/slotDetail/:key" element={<SlotDetail/>} />
                        <Route path="/product/:key" element={<Product/>} />

                        <Route path="/activity/slotDetail/:key/joinSuccess" element={<CompletionDialog/>} />
                        <Route path="/activity/slotDetail/:key/cancelSuccess" element={<CancelDialog/>} />
                        <Route path="/activity/slotDetail/:key/cancelFailed" element={<CancelFail/>} />
                        <Route path="/activity/slotDetail/:key/joinFailed" element={<DuplicatedTimeslot/>} />
                        <Route path="/activity/slotDetail/:key/slotFull" element={<FullTimeslot/>} />
                        <Route path="/activity/slotDetail/:key/slotPass" element={<PassTimeslot/>} />
                        
                        
                        <Route path="/signup" element={<SignUp/>} />
                        <Route path="/confirm_code/:key" element={<ConfirmCode/>} />
                        <Route path="/login" element={<Login/>} />
                        <Route path="/forgotPassword" element={<ForgotPassword/>} />
                        <Route path="/confirm_forgot/:key" element={<ConfirmForgot/>} />
                        
                        <Route path="/profile/myQRcode" element={<MyQRCode/>}/>
                    </Routes>
                </main>
                <Routes>
                    <Route index path="/" element={<MainFooter path="home"/>}/>
                    <Route path="/activities/:key" element={<MainFooter path="activities"/>}/>
                    <Route path="/institutions/:key" element={<MainFooter path="activities"/>}/>
                    <Route path="/my_activities" element={<MainFooter path="my_activities"/>}/>
                    <Route path="/my_history" element={<MainFooter path="my_activities"/>}/>
                    <Route path="/exchange_goods/:key" element={<MainFooter path="exchange_goods"/>}/>
                    <Route path="/exchange_history/:key" element={<MainFooter path="exchange_goods"/>}/>
                    
                    <Route path="/activity/:key" element={""} />
                    <Route path="/institution/:key" element={""} />
                    <Route path="/activity/slotPicker/:key" element={""} />
                    <Route path="/activity/slotDetail/:key" element={""} />
                    <Route path="/product/:key" element={""} />

                    <Route path="/activity/slotDetail/:key/joinSuccess" element={""} />
                    <Route path="/activity/slotDetail/:key/cancelSuccess" element={""} />
                    <Route path="/activity/slotDetail/:key/cancelFailed" element={""} />
                    <Route path="/activity/slotDetail/:key/joinFailed" element={""} /> 
                    <Route path="/activity/slotDetail/:key/slotFull" element={""} />
                    <Route path="/activity/slotDetail/:key/slotPass" element={""} />
                    
                    <Route path="/signup" element={""} />
                    <Route path="/confirm_code/:key" element={""} />
                    <Route path="/login" element={""} />
                    <Route path="/forgotPassword" element={""} />
                    <Route path="/confirm_forgot/:key" element={""} />
                        
                    <Route path="/profile/myQRcode" element={""}/>
                </Routes>
                
                <style jsx>
                {`
                    main {
                        flex: 1;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        min-height: 100vh;
                        min-width: 350px;
                    }

                    .div-footer {
                        background-color: #ffffff;
                        display: inline-table;
                        width: 100%;
                        bottom: 0;
                        position: fixed;
                        flex-direction: column;
                    }
                    .div-footer-button {
                        scale: 0.85;
                        display: table-cell;
                        width: 25%;
                        text-align: center;
                    }
                    .img-footer-button {
                        object-fit: auto;
                        object-position: center;
                    }
                `}
                </style>

                <style jsx global>
                {`
                    html,
                    body {
                    padding: 0;
                    margin: 0;
                    font-family:
                        -apple-system,
                        BlinkMacSystemFont,
                        Segoe UI,
                        Roboto,
                        Oxygen,
                        Ubuntu,
                        Cantarell,
                        Fira Sans,
                        Droid Sans,
                        Helvetica Neue,
                        sans-serif;
                    }
                    * {
                        box-sizing: border-box;
                    }
                `}
                </style>
            </HashRouter>
        </Account>
    );
}

export default App;
