const MainHeader = () => {
    return (
        <div className="header">
            <div className="div-header">
                <div className="div-setting">
                    <img className="img-setting"
                        loading="lazy"
                        src="\assets\icon\setting.svg"
                    />
                </div>
                <div className="div-logo">
                    <img className="img-icon"
                        loading="lazy"
                        src="\assets\icon\logo_horizontal-01.svg"
                    />
                </div>
                <div className="div-noti">
                    <img className="img-noti"
                        loading="lazy"
                        src="\assets\icon\noti_new.svg"
                    />
                </div>
            </div>
            <style jsx>
            {`
                .header {
                    top: 0;
                    position: sticky;
                    z-index: 99;
                }
                .div-header {
                    background-color: var(--primary-main, #82bbb6);
                    display: inline-table;
                    width: 100%;
                    padding-left: 18px;
                    padding-right: 18px;
                    padding-top: 10px;
                    padding-bottom: 5px;
                    flex-direction: column;
                    top: 0;
                    position: sticky;
                    min-width: 350px;
                }
                .div-setting {
                    // display: table-cell;
                    display: none;
                    width: 33%;
                    text-align: left;
                }
                .div-logo {
                    display: table-cell;
                    width: 34%;
                    text-align: center;
                }
                .div-noti {
                    //display: table-cell;
                    display: none;
                    width: 33%;
                    text-align: right;
                }

                .img-setting {
                    object-fit: auto;
                    object-position: center;
                    vertical-align: bottom;
                }
                .img-icon {
                    aspect-ratio: 4.55;
                    object-fit: auto;
                    object-position: center;
                    width: 111px;
                    max-width: 100%;
                }
                .img-noti {
                    aspect-ratio: 1;
                    object-fit: auto;
                    object-position: center;
                    width: 24px;
                }
            `}
            </style>
        </div>
    );
}
export default MainHeader;