'use client'
 
import { useState } from 'react'
import { Routes, Route, HashRouter, Link } from 'react-router-dom';
import ActivityTabs from '../activities/tabs/activity_tabs';

const ActivitiesHeader = ({path}) => {

    var activities_switch = "rgba(0, 0, 0, 0.38)";
    var institutions_switch = "rgba(0, 0, 0, 0.38)";

    if (path === "activities"){
        activities_switch = "#107970";
    } else if (path === "institutions"){
        institutions_switch = "#107970";
    }

    return (
        <div className="oheader">
            <header className="activity-organizer">
                <div className="header">
                    <h3 className="activity-title"><Link to="/activities/all" style={{color: activities_switch, textDecoration: "none"}}>活動</Link></h3>
                    <h3 className="organizer-title"><Link to="/institutions/all" style={{color: institutions_switch, textDecoration: "none"}}>舉辦機構</Link></h3>
                </div>
                <nav className="icon-container">
                    <img loading="lazy" src="\assets\icon\search.svg" className="icon" alt="Activity icon" />
                    <img loading="lazy" src="\assets\icon\grid_view.svg" className="icon" alt="Organizer icon" style={{ scale: "1.5", }} />
                </nav>
            </header>
            <ActivityTabs/>
            <style jsx>
            {`
                a, a:visited, a:hover, a:active {
                    text-decoration: none;
                    color: inherit;
                }
                .header {
                    top: 0;
                    position: sticky;
                    z-index: 99;
                }
                .oheader {
                    top: 0;
                    position: sticky;
                    z-index: 99;
                    width: 100%;
                }
                .activity {
                    width: 100%;
                    min-height: 100vh;
                    background-color: var(--Background-paper-elevation-0, #f2f2f2);
                }
                .activity-organizer {
                    justify-content: space-between;
                    background-color: var(--Background-paper-elevation-0, #f2f2f2);
                    display: flex;
                    width: 100%;
                    gap: 10px;
                    padding: 10px 16px 10px 8px;
                }
                .header {
                    display: flex;
                    gap: 10px;
                    font-size: 24px;
                    font-weight: 400;
                    white-space: nowrap;
                    letter-spacing: -1.5px;
                    justify-content: space-between;
                    margin: auto 0;
                    padding: 6px 11px;
                }
                .activity-title {
                    color: var(--Primary-dark, #107970);
                    font-feature-settings: "clig" off, "liga" off;
                    font-family: YuPearl, sans-serif;
                    margin: 0;
                    padding-right: 15px;
                    border-right: 1.5px solid black;
                    text-decoration: none;
                }
                .organizer-title {
                    color: var(--text-tertiary, rgba(0, 0, 0, 0.38));
                    font-feature-settings: "clig" off, "liga" off;
                    font-family: YuPearl, sans-serif;
                    margin: 0;
                    text-decoration: none;
                }
                .icon-container {
                    justify-content: space-between;
                    // display: flex;
                    display: none;
                    gap: 25px;
                    padding: 8px 8px 8px 32px;
                }
                .icon {
                    aspect-ratio: 1;
                    object-fit: auto;
                    object-position: center;
                    width: 24px;
                }
            `}
            </style>
        </div>
    );
}
export default ActivitiesHeader;