import React, { useState } from 'react';
import { useParams, useNavigate, useLocation, Link  } from 'react-router-dom';
import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";
import UserPool from '../../UserPool';

const ConfirmForgot = () => {
    
    let navigate = useNavigate();
    const path = useLocation().pathname;
    const path_array = path.split('/');
    const userName = path_array[2];

    const [confirmationCode, setConfirmationCode] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [warning, setWarning] = useState();

    const onBack = () => {
        navigate(-1)
    };

    const onConfirm = () => {
        if (newPassword === confirmPassword){
            const user = new CognitoUser({
                Username: userName,
                Pool: UserPool,
            });
    
            user.confirmPassword(confirmationCode, newPassword, {
                onSuccess: () => {
                    alert("重設成功，請重新登入。");
                    navigate('/login')
                },
                onFailure: (err) => {
                    console.error(err);
                    setWarning(<p style={{color: 'red'}}>驗證碼錯誤!</p>)
                }
            });
        } else {
            setWarning(<p style={{color: 'red'}}>密碼與確認密碼不符!</p>)
        }
    }


    return (
        <div className="container">
            <header className="header">
                <button className="back-button" onClick={onBack}>
                    <img src="\assets\icon\back.svg" alt="" className="back-icon" />
                    <span>返回</span>
                </button>
            </header>
            <h1 className="title">重設密碼</h1>
            <div className="form-container">
                <p className="form-title">請輸入驗證碼</p>
                <input
                    type="text"
                    id="verificationCode"
                    className="verification-input"
                    placeholder="驗證碼"
                    onChange={(event) => setConfirmationCode(event.target.value)}
                />
                <p className="form-title">新密碼</p>
                <input
                    type="password"
                    id="newPassword"
                    className="verification-input"
                    placeholder="新密碼"
                    onChange={(event) => setNewPassword(event.target.value)}
                />
                <p className="form-title">再次輸入新密碼</p>
                <input
                    type="password"
                    id="confirmPassword"
                    className="verification-input"
                    placeholder="再次輸入新密碼"
                    onChange={(event) => setConfirmPassword(event.target.value)}
                />
                {warning}     
                <button onClick={onConfirm} className="submit-button">確定</button>
            </div>

            <style jsx>
            {`
                .container {
                    display: flex;
                    max-width: 480px;
                    width: 100%;
                    flex-direction: column;
                    align-items: center;
                    margin: 0 auto;
                    padding: 10px 0;
                    min-height: 100vh;
                    background-color: var(--Background-paper-elevation-0, #f2f2f2);
                }
                .header {
                    top: 0;
                    position: sticky;
                    z-index: 99;
                    display: flex;
                    justify-content: space-between;
                    align-self: flex-start;
                    padding: 15.5px 16px 12px 8px;
                    font-size: 20px;
                    font-weight: 500;
                    letter-spacing: 0.15px;
                    background-color: var(--Background-paper-elevation-0, #f2f2f2);
                }
                .back-button {
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    color: #107970;
                    background: none;
                    border: none;
                    cursor: pointer;
                    font-size: inherit;
                    font-weight: inherit;
                }
                .back-icon {
                    width: 16px;
                    height: 20px;
                    object-fit: contain;
                }
                .title {
                    color: var(--text-primary, rgba(0, 0, 0, 0.87));
                    text-align: center;
                    font-feature-settings: "clig" off, "liga" off;
                    margin-top: 30px;
                    margin-bottom: 0px;
                    font: 400 32px Caveat Brush, -apple-system, Roboto, Helvetica, sans-serif;
                }
                .form-container {
                    align-self: stretch;
                    display: flex;
                    margin-top: 10px;
                    width: 100%;
                    flex-direction: column;
                    font-size: 20px;
                    font-weight: 500;
                    white-space: nowrap;
                    letter-spacing: 0.15px;
                    padding: 0 28px;
                }
                .form-title {
                    color: var(--text-primary, rgba(0, 0, 0, 0.87));
                    text-align: center;
                    font-feature-settings: "clig" off, "liga" off;
                    letter-spacing: -1.5px;
                    align-self: self-start;
                    font: 400 20px YuPearl, sans-serif;
                    margin-bottom: 5px;
                }
                .verification-input {
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 1;
                    font-feature-settings: "clig" off, "liga" off;
                    text-overflow: ellipsis;
                    font-family: YuPearl, sans-serif;
                    border-radius: 8px;
                    background-color: #fff;
                    color: var(--text-secondary, rgba(0, 0, 0, 0.6));
                    padding: 8px 16px;
                    border: 1px solid #e0e0e0;
                }
                .submit-button {
                    font-feature-settings: "clig" off, "liga" off;
                    font-family: YuPearl, sans-serif;
                    border-radius: 4px;
                    background-color: #107970;
                    margin-top: 40px;
                    color: var(--secondary-contrast, #fff);
                    padding: 8px 22px;
                    border: 0;
                }
                .visually-hidden {
                    position: absolute;
                    width: 1px;
                    height: 1px;
                    padding: 0;
                    margin: -1px;
                    overflow: hidden;
                    clip: rect(0, 0, 0, 0);
                    white-space: nowrap;
                    border-width: 0;
                }
            `}
            </style>
        </div>
    );
};

export default ConfirmForgot;
