import React from 'react';
import { useParams, useNavigate, useLocation, Link  } from 'react-router-dom';

const TabButton = ({ label, type, list, isActive }) => {
    return (
        <Link to={`/${type}/${list}`}>
            <button className={`tab-button ${isActive ? 'active' : ''}`}>
                {label}
            </button>
            <style jsx>{`
                .tab-button {
                    scale: 1.5;
                    font-feature-settings: "clig" off, "liga" off;
                    font-family: YuPearl, sans-serif;
                    border-radius: 100px;
                    border: 1px solid var(--Primary-main, #82bbb6);
                    background-color: transparent;
                    color: inherit;
                    padding: 4px;
                    margin-left: 10px;
                    margin-right: 30px;
                    cursor: pointer;
                    transition: background-color 0.3s, color 0.3s;
                }
                .tab-button.active {
                    background-color: var(--Primary-dark, #107970);
                    color: var(--primary-contrast, #fff);
                    border-color: var(--Primary-dark, #107970);
                }
            `}
            </style>
        </Link>
    );
};

export default TabButton;