import React, { useEffect } from "react";
import Tag from '../common/tags/tags';

const ActivityDetail = (event) => {
    
    var participantsNo = "";
    if (event.state.minParticipants === event.state.maxParticipants)
        participantsNo = "每節 " + event.state.minParticipants + " 人"
    else
        participantsNo = "每節 " + event.state.minParticipants + " - " + event.state.maxParticipants + " 人"

    return (
        <>
            <div className="activity-detail">
                <div className="info-row">
                    <div className="info-label">主辦機構</div>
                    <div className="info-value">{event.state.organizationName}</div>
                </div>
                <div className="info-row">
                    <div className="info-label">活動性質</div>
                    <div className="info-value">{event.state.property}</div>
                </div>
                <div className="info-row">
                    <div className="info-label">參加人數</div>
                    <div className="info-value">{participantsNo}</div>
                </div>
                <div className="info-row">
                    <div className="info-label">備註</div>
                    <div className="info-value">{event.state.remarks}</div>
                </div>
                <div className="info-row">
                    <div className="info-label">分類</div>
                    <div className="info-value">{event.state.category}</div>
                </div>
                <div className="info-row">
                    <div className="info-label">標籤</div>
                    <div className="tag-container">
                    {event.state.tags.map((tag, index) => (
                        <Tag text={tag} />
                    ))}
                    </div>
                </div>
            </div>
            <style>
            {`
                .activity-detail {
                    align-self: stretch;
                    display: flex;
                    max-width: 353px;
                    flex-direction: column;
                    font-weight: 500;
                    letter-spacing: 0.15px;
                    margin: 0px 20px 20px 20px;
                }
                .info-row {
                    justify-content: center;
                    display: flex;
                    margin-top: 10px;
                    padding-right: 20px;
                    gap: 20px;
                    font-size: 20px;
                    color: rgba(0, 0, 0, 0.38);
                    white-space: nowrap;
                }
                .info-label {
                    font-feature-settings: "clig" off, "liga" off;
                    text-overflow: ellipsis;
                    font-family: YuPearl, sans-serif;
                    width: 30%;
                }
                .info-value {
                    font-feature-settings: "clig" off, "liga" off;
                    text-overflow: ellipsis;
                    font-family: YuPearl, sans-serif;
                    flex: 1;
                    width: 100px;
                    text-wrap: pretty;
                }
                .tag-container {
                    display: flex;
                    padding-right: 34px;
                    gap: 8px;
                    font-size: 16px;
                    color: var(--success-contrast);
                    text-align: center;
                    flex: 1;
                } 
            `}
            </style>
        </>
    );
}

export default ActivityDetail;
