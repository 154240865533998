import React, { useState } from 'react';
import { useParams, useNavigate, useLocation, Link  } from 'react-router-dom';
import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";
import UserPool from '../../UserPool';

const ConfirmCode = () => {
    
    let navigate = useNavigate();
    const path = useLocation().pathname;
    const path_array = path.split('/');
    const userName = path_array[2];

    const [confirmationCode, setConfirmationCode] = useState("");
    const [warning, setWarning] = useState();

    const onBack = () => {
        navigate(-1)
    };

    const onConfirm = () => {
        const user = new CognitoUser({
            Username: userName,
            Pool: UserPool,
        });

        user.confirmRegistration(confirmationCode, true, function(err, result) {
            if (err) {
                setWarning(<p style={{color: 'red'}}>驗證碼錯誤!</p>)
                return({state: userName})
            } else {
                alert("驗證成功，請重新登入。")
                navigate('/login')
            }
        });
    }


    return (
        <div className="container">
            <header className="header">
                <button className="back-button" onClick={onBack}>
                    <img src="\assets\icon\back.svg" alt="" className="back-icon" />
                    <span>返回</span>
                </button>
            </header>
            <form className="form-container">
                <h2 className="form-title">請輸入驗證碼</h2>
                <input
                    type="text"
                    id="verificationCode"
                    className="verification-input"
                    placeholder="驗證碼"
                    onChange={(event) => setConfirmationCode(event.target.value)}
                />
                {warning}     
                <button className="submit-button" onClick={onConfirm}>確定</button>
            </form>

            <style jsx>
            {`
                .container {
                    display: flex;
                    max-width: 480px;
                    width: 100%;
                    flex-direction: column;
                    align-items: center;
                    margin: 0 auto;
                    padding: 10px 0;
                    min-height: 100vh;
                    background-color: var(--Background-paper-elevation-0, #f2f2f2);
                }
                .header {
                    top: 0;
                    position: sticky;
                    z-index: 99;
                    display: flex;
                    justify-content: space-between;
                    align-self: flex-start;
                    padding: 15.5px 16px 12px 8px;
                    font-size: 20px;
                    font-weight: 500;
                    letter-spacing: 0.15px;
                    background-color: var(--Background-paper-elevation-0, #f2f2f2);
                }
                .back-button {
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    color: #107970;
                    background: none;
                    border: none;
                    cursor: pointer;
                    font-size: inherit;
                    font-weight: inherit;
                }
                .back-icon {
                    width: 16px;
                    height: 20px;
                    object-fit: contain;
                }
                .title {
                    color: var(--secondary-main, #ff9800);
                    text-align: center;
                    font-feature-settings: "clig" off, "liga" off;
                    margin-top: 59px;
                    font: 400 48px/108% Caveat Brush, -apple-system, Roboto, Helvetica, sans-serif;
                }
                .form-container {
                    align-self: stretch;
                    display: flex;
                    margin-top: 99px;
                    width: 100%;
                    flex-direction: column;
                    font-size: 20px;
                    font-weight: 500;
                    white-space: nowrap;
                    letter-spacing: 0.15px;
                    padding: 0 28px;
                }
                .form-title {
                    color: var(--text-primary, rgba(0, 0, 0, 0.87));
                    text-align: center;
                    font-feature-settings: "clig" off, "liga" off;
                    letter-spacing: -1.5px;
                    align-self: center;
                    font: 400 28px YuPearl, sans-serif;
                }
                .verification-input {
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 1;
                    font-feature-settings: "clig" off, "liga" off;
                    text-overflow: ellipsis;
                    font-family: YuPearl, sans-serif;
                    border-radius: 8px;
                    background-color: #fff;
                    margin-top: 24px;
                    color: var(--text-secondary, rgba(0, 0, 0, 0.6));
                    padding: 8px 16px;
                    border: 1px solid #e0e0e0;
                }
                .submit-button {
                    font-feature-settings: "clig" off, "liga" off;
                    font-family: YuPearl, sans-serif;
                    border-radius: 4px;
                    background-color: #107970;
                    margin-top: 24px;
                    color: var(--secondary-contrast, #fff);
                    padding: 8px 22px;
                }
                .visually-hidden {
                    position: absolute;
                    width: 1px;
                    height: 1px;
                    padding: 0;
                    margin: -1px;
                    overflow: hidden;
                    clip: rect(0, 0, 0, 0);
                    white-space: nowrap;
                    border-width: 0;
                }
            `}
            </style>
        </div>
    );
};

export default ConfirmCode;
